import { Fragment } from "react";
import cl from "styles/pages/[fieldId].module.scss";
import Input from "components/input/InputComponent";
import CustomSelect from "../CustomSelect";
import { cloneDeep } from "lodash";
import { useSearchParams } from "react-router-dom";

const FormPostings = ({
  typeNames,
  documentNumber,
  setDocumentNumber,
  documentDate,
  setDocumentDate,
  emptyLine,
  groups,
  lines,
  setLines,
  filterNames,
  filterArticles,
  onChangeName,
  onChangePos,
  addLine,
  scrollPlus,
  provided,
  storage,
  setStorage,
  storages,
  employee,
  setEmployee,
  addressList,
  setAllAddress,
  storageChecker,
  sortName,
  sortFn,
}) => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  // Классы таблицы
  const tableClasses = [cl.mainTable, cl.top0, "table", "table-responsive"];

  // Добавление колонки с пользовательским вводом
  const addColumn = (index) => {
    const copy = cloneDeep(lines);
    copy[index].userPositions.push(
      JSON.parse(JSON.stringify(emptyLine.userPositions[0]))
    );
    copy[index].amounts.push(JSON.parse(JSON.stringify(emptyLine.amounts[0])));
    setLines(copy);
  };

  // Кастомное удаление строки
  const deleteLine = (index) => {
    const copy = Object.assign([], lines);
    copy[index.index].userPositions.splice(index.ind, 1);
    copy[index.index].amounts.splice(index.ind, 1);
    if (copy[index.index].amounts.length === 0) copy.splice(index.index, 1);
    setLines(copy);
  };

  return (
    <table className={tableClasses.join(" ")}>
      <thead className={cl.top0 + " theadBordered thead-dark"}>
        <tr>
          <th colSpan="2" className={cl.borderNone}>
            {documentNumber !== "" && (
              <>
                {typeNames[type]} №&nbsp;
                {lines[0].is_copy === 0 ? (
                  documentNumber
                ) : provided === 1 ? (
                  documentNumber
                ) : (
                  <Input
                    type="text"
                    name="documentNumber"
                    autocomplete="off"
                    placeholder="Номер документа"
                    value={documentNumber}
                    setValue={(name, value) => setDocumentNumber(value)}
                  />
                )}
              </>
            )}
          </th>
          <th className={cl.borderNone + " " + cl.dateInput}>
            {documentNumber !== "" && <>от&nbsp;</>}
            {provided === 0 ? (
              <Input
                type="text"
                name="documentDate"
                autocomplete="off"
                mask="99.99.9999 99:99"
                placeholder="Дата"
                value={documentDate}
                setValue={(name, value) => setDocumentDate(value)}
              />
            ) : (
              documentDate
            )}
          </th>
          <th>
            {lines.length > 0 && lines[0].author && (
              <>
                Автор:&nbsp;
                {lines[0].author}
                <br />
              </>
            )}
            Бригадир:&nbsp;
            <Input
              type="text"
              name="employee"
              value={employee}
              setValue={setEmployee}
              placeholder="Бригадир"
              title={employee}
            />
          </th>
          <th colSpan="3">Остатки по складам:</th>
          <th>
            {provided === 0 ? (
              <CustomSelect
                options={storages}
                currentValue={storage}
                changeHandler={setStorage}
                placeholder="Склад"
                name="storage"
              />
            ) : (
              storage
            )}
          </th>
          <th>
            {provided === 0 && (
              <CustomSelect
                options={storage === "" ? [] : addressList}
                currentValue=""
                eraseValue={true}
                changeHandler={setAllAddress}
                placeholder="Адрес для всех"
                name="allAddress"
              />
            )}
          </th>
          {provided === 0 && (
            <th rowSpan="3" colSpan="2" className={cl.borderNone} />
          )}
        </tr>
        <tr>
          <th rowSpan="2">№</th>
          <th
            rowSpan="2"
            className={sortName === "name" ? "sort sorted" : "sort"}
            onClick={() => sortFn("name")}
          >
            <span>Название</span>
          </th>
          <th
            rowSpan="2"
            className={sortName === "article" ? "sort sorted" : "sort"}
            onClick={() => sortFn("article")}
          >
            <span>Артикул</span>
          </th>
          <th
            rowSpan="2"
            className={sortName === "b_group" ? "sort sorted" : "sort"}
            onClick={() => sortFn("b_group")}
          >
            Группа
          </th>
          <th rowSpan="2">Всего:</th>
          <th colSpan="2">В том числе:</th>
          <th rowSpan="2">Приход</th>
          <th rowSpan="2">Адрес прихода</th>
        </tr>
        <tr>
          <th>Кол-во</th>
          <th>Адрес</th>
        </tr>
      </thead>
      <tbody>
        {lines.map((line, index) => (
          <Fragment key={index.toString()}>
            {line.amounts.map((ln, i) => (
              <tr key={i.toString()} className={line.isError ? cl.error : ""}>
                {i === 0 && (
                  <>
                    <td rowSpan={line.amounts.length}>{index + 1}</td>
                    <td
                      rowSpan={line.amounts.length}
                      className={cl.name}
                      onClick={storageChecker}
                    >
                      {provided === 0 && line.name === "" ? (
                        <CustomSelect
                          options={filterNames(line.b_group_id)}
                          currentValue={line.name}
                          currentIndex={index}
                          name="name"
                          data={lines}
                          groups={groups}
                          changeHandler={onChangeName}
                          placeholder="Наименование"
                        />
                      ) : (
                        line.name
                      )}
                    </td>
                    <td rowSpan={line.amounts.length} onClick={storageChecker}>
                      {provided === 0 && line.article === "" ? (
                        <CustomSelect
                          options={filterArticles(line.b_group_id)}
                          currentValue={line.article}
                          currentIndex={index}
                          name="article"
                          data={lines}
                          groups={groups}
                          changeHandler={onChangeName}
                          placeholder="Артикул"
                        />
                      ) : (
                        line.article
                      )}
                    </td>
                    <td rowSpan={line.amounts.length} onClick={storageChecker}>
                      {provided === 0 && line.b_group === "" ? (
                        <CustomSelect
                          options={groups}
                          currentValue={line.b_group}
                          currentIndex={index}
                          name="b_group"
                          changeHandler={onChangeName}
                          placeholder="Группа"
                        />
                      ) : (
                        line.b_group
                      )}
                    </td>
                    <td rowSpan={line.amounts.length} className={cl.right}>
                      {new Intl.NumberFormat("ru-RU").format(line.totalAmount)}
                    </td>
                  </>
                )}

                <td className={cl.right}>
                  {new Intl.NumberFormat("ru-RU").format(ln.amount)}
                </td>
                <td className={cl.address}>{ln.address}</td>

                <td className={cl.right}>
                  {provided === 0 ? (
                    <Input
                      type="number"
                      name="amount"
                      placeholder="Кол-во"
                      value={line.userPositions[i].amount}
                      setValue={onChangePos}
                      currentIndex={{ index: index, ind: i }}
                    />
                  ) : (
                    new Intl.NumberFormat("ru-RU").format(
                      line.userPositions[i].amount
                    )
                  )}
                </td>
                <td>
                  {provided === 0 ? (
                    <CustomSelect
                      options={storage === "" ? [] : addressList}
                      currentValue={line.userPositions[i].address}
                      currentIndex={{ index: index, ind: i }}
                      name="address"
                      changeHandler={onChangePos}
                      placeholder="Адрес"
                    />
                  ) : (
                    line.userPositions[i].address
                  )}
                </td>

                {provided === 0 && (
                  <td className="iconed">
                    <span
                      className="roundIcon material-icons"
                      onClick={() => deleteLine({ index: index, ind: i })}
                      title="Удалить строку"
                    >
                      delete_outline
                    </span>
                  </td>
                )}

                {i === 0 && provided === 0 && (
                  <td rowSpan={line.amounts.length} className="iconed">
                    <span
                      className="roundIcon material-icons"
                      onClick={() => addColumn(index)}
                      title="Добавить колонку"
                    >
                      add
                    </span>
                  </td>
                )}
              </tr>
            ))}
          </Fragment>
        ))}
        {provided === 0 && (
          <tr className="adding">
            <td className="iconed">
              <span
                className="roundIcon material-icons"
                onClick={addLine}
                title="Добавить строку"
              >
                add
              </span>
            </td>
          </tr>
        )}
        <tr style={{ height: "300px" }} />
        <tr ref={scrollPlus} />
      </tbody>
    </table>
  );
};

export default FormPostings;

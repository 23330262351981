import cl from "styles/components/Button.module.scss";

const Button = ({children, type, disabled = false, onClick = false, inProcess = false}) => {
	const classes = [cl.button, cl[type]];
	if(disabled) classes.push(cl.disabled);
	
	const clickHandler = () => {
		if(disabled || onClick === false || inProcess) return;
		else onClick();
	}
	
	return (
		<span className={classes.join(" ")} onClick={clickHandler}>
			{inProcess
				? <span className="material-icons spinner">scatter_plot</span>
				: children
			}
		</span>
	);
};

export default Button;
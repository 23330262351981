import cl from "styles/components/cabinet/UsersTable.module.scss";

import {useContext, useEffect} from "react";
import Button from "components/ButtonComponent";
import Indicator from "components/Indicator";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "providers/AppContextProvider";
import { getUser } from "helpers/storage";
import { fetchFactories } from "redux/slices/docSlice";

const Index = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { setMenuCondition, setCrumbs } = useContext(AppContext);
  
	const { factories } = useSelector((state) => state.doc);

	useEffect(() => {
		// Сброс условия меню
		setMenuCondition({});
		
		// Запись хлебных крошек
		setCrumbs([{name: "Производство", url: ""}]);
	}, []);

	useEffect(() => {
		dispatch(fetchFactories());
	}, []);
	
	return (
		<>
			
			<title>Производство</title>
						
			<div style={{padding: "16px"}}>
				&nbsp;
				<Indicator type="danger"/> - Производство создано &nbsp;
				<Indicator type="success"/> - Производство проведено в Системе
			</div>
			
			<table className={cl.usersTable}>
				<thead>
					<tr>
						<th colSpan="2">
							{getUser().accessLevel > 1 &&
								<Button type="accent" onClick={() => navigate("/factory/new")}>
									Новое производство
								</Button>
							}
						</th>
						<th/>
						<th>
							<Button type="white" onClick={() => navigate("/archive/factory")}>
								Архив
							</Button>
						</th>
						<th>
							<Button type="white" onClick={() => window.open("/faq/factory")}>
								Инструкция
							</Button>
						</th>
					</tr>
					<tr>
						<th>№</th>
						<th>Дата в Системе</th>
						<th>Дата производства</th>
						<th/>
						<th/>
					</tr>
				</thead>
				<tbody>
					{factories.data.map((factor, index) =>
						<tr key={index.toString()}>
							<td>
								<span>{factor.document_number}</span>
							</td>
							<td>
								<span>{factor.document_date}</span>
							</td>
							<td>
								<span>{factor.factory_date}</span>
							</td>
							<td>
								<span className={cl.icon + " material-icons"} title="Посмотреть"
										onClick={() => navigate("/factory/" + factor.id)}>
									visibility
								</span>
							</td>
							<td>
								<Indicator type={factor.provided === 1 ? "success" : "danger"}/>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</>
	)
}

// export async function getServerSideProps(){
// 	const apiToken = "Bearer ~ybqF7}S9G9a5@8}aIex";
	
// 	const factoryResponse = await axios.get("factory/all", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const factory = factoryResponse.data.result;
	
// 	return {props: {factory}}
// }

export default Index;
const mobile = {
  SCREEN_WIDTH: 768,
  LABEL: "mobile",
  PREFIX: "md",
};

const tablet = {
  SCREEN_WIDTH: 1200,
  LABEL: "tablet",
  PREFIX: "xl",
};

const desktop = {
  LABEL: "desktop",
};

export {
  mobile,
  tablet,
  desktop,
};

import FactoryForm from "components/factory/FactoryForm";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "providers/AppContextProvider";
import { getUser } from "helpers/storage";
import { fetchMapAddresses } from "redux/slices/mapSlice";
import { fetchGroups } from "redux/slices/businessSlice";

const New = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { mapAddresses } = useSelector((state) => state.map);
  const { groups } = useSelector((state) => state.business);

  const { setCrumbs, setMenuCondition } = useContext(AppContext);
  useEffect(() => {
    // Перенаправление на главную, если нет доступа
    if (getUser().accessLevel < 2) navigate("/");

    // Сброс условия меню
    setMenuCondition({});

    // Запись хлебных крошек
    setCrumbs([
      { name: "Производство", url: "/factory" },
      { name: "Новый документ", url: "" },
    ]);
  }, []);

  useEffect(() => {
    dispatch(fetchMapAddresses());
    dispatch(fetchGroups());
  }, []);

  return (
    <>
      <title>Новое производство</title>

      <FactoryForm
        alert={alert}
        setMenuCondition={setMenuCondition}
        factory={[]}
        groups={groups.data}
        userLevel={() => getUser().accessLevel}
        addressList={mapAddresses.data}
        Provided={0}
      />
    </>
  );
};

export default New;

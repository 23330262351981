
import axios from "axios";
import HistoryTable from "components/archive/HistoryTable";
import {useEffect, useState} from "react";

const Index = ({children, alert, userLevel, History, setCrumbs}) => {
	const [history, setHistory] = useState(History);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "Архивы", url: "/archive"},
			{name: "История", url: ""}
		]);
	}, []);
	
	return (
		<>
			
				<title>Архив | истории</title>
			
			
			{children}
			
			<div className="cabinetMain">
				<HistoryTable alert={alert} userLevel={userLevel} history={history} setHistory={setHistory}/>
			</div>
		</>
	);
};

export async function getServerSideProps(){
	const apiToken = "Bearer ~ybqF7}S9G9a5@8}aIex";
	
	const historyResponse = await axios.get("history/getArchives", {
		headers: {
			Authorization: apiToken
		}
	});
	const History = historyResponse.data.result;
	
	return {props: {History}};
}

export default Index;
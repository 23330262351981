import cl from "styles/components/cabinet/UsersTable.module.scss";
import Switch from "react-switch";
import axios from "axios";
import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

const UsersTable = ({users, setUsers, alert}) => {
	const navigate = useNavigate();
	const router = {
		pathname: window.location.pathname,
		push: (p) => navigate(p),
		query: { type: (new URLSearchParams(window.location.search)).get("type") },
	};
	
	// Размер switch-элементов для разных экранов
	const [switchSize, setSwitchSize] = useState({height: 28, width: 56});
	useEffect(() => {
		if(window.screen.width <= 768) setSwitchSize({height: 20, width: 40});
	}, []);
	
	// Смена активного статуса пользователя
	const switchActive = async(checked, e, index) => {
		const copy = Object.assign([], users);
		copy[index].active = checked === true ? 1 : 0;
		if(copy[index].active === 0) copy[index].isEditor = 0;
		setUsers(copy);
		
		await axios.post("user/setLevel/" + copy[index].id, {
			level: checked === true ? 1 : 0
		});
	}
	
	// Смена статуса редактора
	const switchEditor = async(checked, e, index) => {
		const copy = Object.assign([], users);
		
		if(copy[index].active === 0) return;
		
		copy[index].isEditor = checked === true ? 1 : 0;
		setUsers(copy);
		
		await axios.post("user/setLevel/" + copy[index].id, {
			level: checked === true ? 2 : 1
		});
	}
	
	// Удаление пользователя
	const remove = (index) => {
		const copy = Object.assign([], users);
		
		const btns = [
			{
				text: "Да",
				handler: function(){
					axios.delete("user/remove/" + copy[index].id, {
						headers: {
							"Authorization": "Bearer " + localStorage.getItem("access_token")
						}
					}).then(() => {
						copy.splice(index, 1);
						setUsers(copy);
						alert("", "danger", 1);
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "danger", 1);
				}
			}
		];
		alert("Подтвердите удаление", "danger", 10000, btns);
	}
	
	return (
		<table className={cl.usersTable}>
			<thead>
				<tr>
					<th>Активен</th>
					<th>Имя</th>
					<th>Email</th>
					<th>Редактор</th>
					<th>
						<span className="material-icons roundIcon" onClick={() => router.push("/signup?cabinet=add")}
								title="Добавить пользователя">add</span>
					</th>
				</tr>
			</thead>
			<tbody>
				{users.map((usr, index) =>
					<tr key={index.toString()}>
						<td>
							<Switch onChange={switchActive} checked={usr.active === 1} id={index.toString()}
									  onColor="#648220" offColor="#b0b1b3" height={switchSize.height} width={switchSize.width}/>
						</td>
						<td>
							<span>{usr.name}</span>
						</td>
						<td>
							<span>{usr.email}</span>
						</td>
						<td>
							<Switch onChange={switchEditor} checked={usr.isEditor === 1} id={index.toString()}
									  onColor="#648220" offColor="#b0b1b3" height={switchSize.height} width={switchSize.width}/>
						</td>
						<td>
							<span className={cl.icon + " " + cl.remove + " material-icons"} title="Удалить"
									onClick={() => remove(index)}>
								close
							</span>
						</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};

export default UsersTable;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { journals } from "constants/endpoints";

const fetchAmountAll = createAsyncThunk("journals/amountAll", async () => {
  const response = await fetcher(journals.AMOUNT_ALL);
  return response.json();
});

const fetchAmountData = createAsyncThunk("journals/amountData", async () => {
  const response = await fetcher(journals.AMOUNT_DATA);
  return response.json();
});

const fetchAmountDataWithRemains = createAsyncThunk("journals/amountDataWithRemains", async (q = "?remains=1") => {
  const response = await fetcher(journals.AMOUNT_DATA + q);
  return response.json();
});

const fetchJournalName = createAsyncThunk("journals/journalName/x", async (id) => {
  const response = await fetcher(`${journals.JOURNAL_NAME}/${id}`);
  return response.json();
});

const fetchJournalSpreadDate = createAsyncThunk("journals/spreadData", async (id) => {
  const response = await fetcher(journals.SPREAD_DATA);
  return response.json();
});

const initialState = {
  amountAll: {
    status: loadingStatus.IDLE,
    data: [],
    error: null,
  },
  amountData: {
    status: loadingStatus.IDLE,
    data: [],
  },
  amountDataWithRemains: {
    status: loadingStatus.IDLE,
    data: [],
  },
  journalSpreadDate: {
    status: loadingStatus.IDLE,
    data: [],
  },
  journalName: "",
};

const journalSlice = createSlice({
  name: "journal",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAmountAll.pending, (state) => {
        state.amountAll.status = loadingStatus.LOADING;
      })
      .addCase(fetchAmountAll.fulfilled, (state, action) => {
        state.amountAll.status = loadingStatus.SUCCEEDED;
        state.amountAll.data = action.payload.result;
        // TODO
        state.amountAll.data.sort((a, b) => {
          return a.sort_name > b.sort_name && a.storage === b.storage ? 1 : -1;
        }).sort((a, b) => {
          if(a.storage === b.storage) return 0
          else return a.storage > b.storage ? 1 : -1;
        });
      })
      .addCase(fetchAmountAll.rejected, (state, action) => {
        state.amountAll.status = loadingStatus.FAILED;
        state.amountAll.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(fetchAmountData.pending, (state) => {
        state.amountData.status = loadingStatus.LOADING;
      })
      .addCase(fetchAmountData.fulfilled, (state, action) => {
        state.amountData.status = loadingStatus.SUCCEEDED;
        state.amountData.data = action.payload.result;
        // TODO: весь подобный треш перенести в API
        state.amountData.data.map((dt, ind) => {
          state.amountData.data[ind].positions = dt.positions.sort((a, b) => {
            if (a.address === b.address) return 0;
            else {
              const aa = a.address.split(".")[0];
              const bb = b.address.split(".")[0];
              
              if(aa === bb || isNaN(parseInt(aa)) || isNaN(parseInt(bb))) return 0;
              else return parseInt(aa) > parseInt(bb) ? 1 : -1;
            }
          });
        });
      })

      .addCase(fetchJournalSpreadDate.pending, (state) => {
        state.journalSpreadDate.status = loadingStatus.LOADING;
      })
      .addCase(fetchJournalSpreadDate.fulfilled, (state, action) => {
        state.journalSpreadDate.status = loadingStatus.SUCCEEDED;
        state.journalSpreadDate.data = action.payload.result;
        // TODO: весь подобный треш перенести в API
        // Сортировка числовых значений в обход строковых
        state.journalSpreadDate.data.map((dt, ind) => {
          state.journalSpreadDate.data[ind].positions = dt.positions.sort((a, b) => {
            if(a.address === b.address) return 0;
            else{
              const aa = a.address.split(".")[0];
              const bb = b.address.split(".")[0];
              
              if(aa === bb || isNaN(parseInt(aa)) || isNaN(parseInt(bb))) return 0;
              else return parseInt(aa) > parseInt(bb) ? 1 : -1;
            }
          });
        });
      })

      .addCase(fetchAmountDataWithRemains.pending, (state) => {
        state.amountDataWithRemains.status = loadingStatus.LOADING;
      })
      .addCase(fetchAmountDataWithRemains.fulfilled, (state, action) => {
        state.amountDataWithRemains.status = loadingStatus.SUCCEEDED;
        state.amountDataWithRemains.data = action.payload.result;
        // TODO: весь подобный треш перенести в API
        state.amountDataWithRemains.data.goods.sort((a, b) => {
          if(a.name === b.name) return 0;
          else return a.name > b.name ? 1 : -1;
        })
      })
  
      .addCase(fetchJournalName.fulfilled, (state, action) => {
        state.journalName = action.payload.result;
      })
  }
});

export default journalSlice.reducer;

export {
  fetchAmountAll,
  fetchAmountData,
  fetchAmountDataWithRemains,
  fetchJournalSpreadDate,
  fetchJournalName,
};

import { useContext, useState } from "react";
import axios from "axios";

import NavbarComponent from "./NavbarComponent";
import SidebarComponent from "./SidebarComponent";
import SpinPlaceholderComponent from "components/preloader/SpinPlaceholderComponent";
import useLayout from "hooks/useLayout";
import { getToken } from "helpers/storage";
import { AppContext } from "providers/AppContextProvider";

const MainContainerComponent = ({ children }) => {

  // axios.defaults.baseURL = "http://agroplanner.loc/api/";
	axios.defaults.baseURL = process.env.REACT_APP_SERVER_ENTRYPOINT;
	// axios.defaults.baseURL = "https://agro.flcity.ru/api/ ";	
  axios.defaults.headers.common["Authorization"] = "Bearer " + getToken();

  // Состояние прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  const { sidenavIsMinified } = useContext(AppContext);
  const { isDesktop } = useLayout();

  // Включение прелоадера
  // useEffect(() => {
  //   const handleRouteChange = () => {
  //     setIsPreloaderActive(true);

  //     // Очистка интервалов при смене страницы
  //     const tm = setTimeout(() => 1 + 1, 3000);
  //     for (let i = 1; i < tm - 1; i++) clearInterval(i);
  //   };

  //   const handleRouterChangeComplete = () => {
  //     setIsPreloaderActive(false);
  //   };

  //   router.events.on("routeChangeStart", handleRouteChange);
  //   router.events.on("routeChangeComplete", handleRouterChangeComplete);

  //   return () => {
  //     router.events.off("routeChangeStart", handleRouteChange);
  //     router.events.off("routeChangeComplete", handleRouterChangeComplete);
  //   };
  // }, []);

  // Скрытие/раскрытие сайдбара на определенных страницах
  // useEffect(() => {
  //   if (router.pathname === "/login" || router.pathname === "/signup")
  //     setSidebarHidden(true);
  //   else setSidebarHidden(false);

  //   let minified = true;
  //   unMinifiedLinks.map((link) => {
  //     if (router.pathname === link) {
  //       minified = false;
  //     }
  //   });
  //   setSidebarMinified(minified);
  // }, [router.pathname]);

  // // Метод скрытия / раскрытия сайдбара
  // const sidebarMinify = (bool) => {
  //   let minified = true;
  //   unMinifiedLinks.map((link) => {
  //     if (router.pathname === link) {
  //       minified = false;
  //     }
  //   });
  //   if (minified) setSidebarMinified(bool);
  // };

  return (
    <div className={`agro-main ${isDesktop ? "agro-desktop" : "agro-mobile"}`}>
      <NavbarComponent />

      <SidebarComponent />

      <main className={`${sidenavIsMinified ? "wide" : ""}`}>
        {children}
      </main>

      {isPreloaderActive ? (
        <SpinPlaceholderComponent isActive={isPreloaderActive} />
      ) : (
        ""
      )}
    </div>
  );
};

export default MainContainerComponent;

import {useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "providers/AppContextProvider";
import Input from "components/input/InputComponent";
import Button from "components/ButtonComponent";
import { postFile } from "redux/slices/inventorySlice";
import { getUser } from "helpers/storage";
import { inventory } from "constants/routes";
import cl from "styles/pages/signup.module.scss";

const InventoryAdd = () => {
  const navigate = useNavigate();
	
	// Флаг отправки
	const [isSended, setIsSended] = useState(false);
	
	// Состояние формы
	const [form, setForm] = useState({
		act: ""
	});

  const { alert, setCrumbs } = useContext(AppContext);

	// Перенаправление на главную, если нет доступа
	useEffect(() => {
		if(getUser().accessLevel < 2) navigate("/");
	}, []);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "Инвентаризация", url: inventory.ALL},
			{name: "Новый документ", url: ""},
		]);
	}, []);
	
	// Метод изменения данных в форме
	const changeHandler = (name, data) => {
		const f = Object.assign({}, form);
		f[name] = data;
		setForm(f);
	}
	
	// Отправка файла на сервер
	const sendFile = async () => {
		// Проверка ввода
		if(form.act === ""){
			alert("Выберите файл", "danger");
			return false;
		}
		
		setIsSended(true);

    const res = await postFile(form.act);
    const response = await res.json();
    if (res.ok) {
			navigate("/inventory/edit/" + response.id);
    } else {
			alert(response.message || response.code, "danger");
			setIsSended(false);
    }
	}
	
	return (
		<>
      <title>Новая инвентаризация</title>			
			
			<div className={cl.main + " container"}>
				<form className={cl.form + " " + cl.withoutLabel}>
					<Input type="file" name="act" value={form.act} setValue={changeHandler}/>
					
					<div className="row">
						<div className="col-sm-12">
							<Button type="accent" onClick={sendFile} inProcess={isSended}>Заполнить акт</Button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default InventoryAdd;
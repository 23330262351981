import { useNavigate } from "react-router-dom";
import cl from "styles/components/MapPanel.module.scss";
import axios from "axios";
import {Fragment, useEffect, useState} from "react";
import ButtonComponent from "components/ButtonComponent";
import CustomSelect from "../CustomSelect";

const MapPanel = ({map, fields, setFields, alert, allowEdit = false}) => {
	const navigate = useNavigate();

	// Локальные состояния с данными карты
	const [mapName, setMapName] = useState(map.name);
	const [panelFields, setPanelFields] = useState(fields);
	const [search, setSearch] = useState("");
	const [storages, setStorages] = useState(map.storages || []);
	const [storagesAllowed, setStoragesAllowed] = useState(map.storages_allowed || []);
	const [storageFiltered, setStorageFiltered] = useState(false);
	
	// Обновление локального состояния при изменении глобального
	useEffect(() => {
		if(search === "" && !storageFiltered) setPanelFields(fields);
	}, [fields]);
	
	// Изменение названия карты
	const editName = (e) => {
		setMapName(e.target.value);
		axios.post("map/edit/" + map.id, {
			mapName: e.target.value
		});
	}
	
	// Поиск по названию поля
	const doSearch = (e) => {
		setSearch(e.target.value);
		if(e.target.value.trim() === "") setPanelFields(fields);
		else setPanelFields(fields.filter(fl => fl.name.toLowerCase().includes(e.target.value.toLowerCase())));
	}
	
	// Установка флага редактирования поля и отправка новых данных по полю
	const setEdit = (index) => {
		// Редактирование полей на карте
		const fieldsCopy = Object.assign([], fields);
		fieldsCopy[fields.indexOf(panelFields[index])] = {
			...fieldsCopy[fields.indexOf(panelFields[index])],
      isEditing: !fieldsCopy[fields.indexOf(panelFields[index])].isEditing,
		};
		setFields(fieldsCopy);
		
		if(panelFields[index].isEditing) return;
		
		axios.put(process.env.REACT_APP_SERVER_ENTRYPOINT + "/fields/updateFieldName/" + panelFields[index].field_id, {
			name: panelFields[index].name
		}).then(() => {
			axios.post(process.env.REACT_APP_SERVER_ENTRYPOINT + "/map/setFields/" + map.id, {
				fields: fieldsCopy
			});
		}).catch(error => {
			if(error.response.data) alert(error.response.data.message, "danger");
			else alert("Ошибка: проблемы с подключением", "danger");
			fieldsCopy[fields.indexOf(panelFields[index])].isEditing = true;
		});
	}
	
	// Удаление поля
	const removeField = (index) => {
		alert(panelFields[index].count > 0 ? "На поле есть данные - удалить?" : "Удалить поле?", "danger", 10000, [
			{
				text: "Да",
				handler: function(){
					const copy = Object.assign([], panelFields);
					const fieldId = copy[index].field_id
					copy.splice(index, 1);
					setPanelFields(copy);
					setFields(fields.filter(field => field.field_id !== fieldId));
					
					axios.post("map/setFields/" + map.id, {
						fields: fields.filter(field => field.field_id !== fieldId)
					}).then(() => {
						axios.delete("map/deleteField/" + fieldId).then(() => {
							alert("Успешно удалено", "success");
						});
					}).catch(() => {
						alert("Ошибка: проблемы с подключением", "danger");
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Открепление склада от карты
	const removeStorage = (index) => {
		axios.post("map/removeMapStorage/" + map.id, {
			storage: storages[index].name
		}).then(() => {
			const allowedCopy = Object.assign([], storagesAllowed);
			allowedCopy.push(storages[index])
			setStoragesAllowed(allowedCopy);
			
			const copy = Object.assign([], storages);
			copy.splice(index, 1);
			setStorages(copy);
		}).catch(() => {
			alert("Ошибка открепления склада", "danger");
		});
	}
	
	// Добавление склада к карте
	const setStorage = (name, value) => {
		axios.post("map/setMapStorage/" + map.id, {
			storage: value
		}).then(() => {
			const copy = Object.assign([], storages);
			copy.push({name: value, value: value});
			setStorages(copy);
			setStoragesAllowed(storagesAllowed.filter(allowed => allowed.name !== value));
		}).catch(() => {
			alert("Ошибка прикрепления склада", "danger");
		});
	}
	
	// Удаление карты
	const deleteMap = () => {
		alert("Удалить карту?", "danger", 10000, [
			{
				text: "Да",
				handler: function(){
					axios.delete("map/delete/" + map.id).then(() => {
						alert("Успешно удалено", "success");
						navigate("/");
					}).catch(() => {
						alert("Ошибка: проблемы с подключением", "danger");
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Добавление склада к полю
	const setFieldStorage = (index, name, value) => {
		axios.post("fields/setFieldStorage/" + panelFields[index].field_id, {
			storage: value
		}).then(() => {
			const copy = Object.assign([], panelFields);
			copy[index].storage = value;
			setPanelFields(copy);
		}).catch(() => {
			alert("Ошибка прикрепления склада", "danger");
		});
	}
	
	// Фильтрация полей по названию склада
	const filterByStorage = (storage) => {
		setPanelFields(fields.filter(field => field.storage === storage));
		setStorageFiltered(true);
	}
	
	// Изменение наименования поля
	const editFieldName = (index, value) => {
		const copy = Object.assign([], panelFields);
		copy[index].name = value;
		setPanelFields(copy);
	}
	
	return (
		<div className={cl.mapPanelContainer}>
			<div className={cl.mapPanel}>
				<div className={cl.mapName}>
					{allowEdit
						? <input type="text" value={mapName} onChange={editName} placeholder="Название карты"
									className={cl.setName}/>
						: mapName
					}
				</div>
				
				<div className={cl.mapStorages}>
					Доступные склады:<br/>
					{storages.map((storage, index) =>
						<div>
							<span onClick={() => filterByStorage(storage.name)}>{storage.name}</span>
							{allowEdit &&
								<span className={"material-icons " + cl.remove} title="Открепить"
										onClick={() => removeStorage(index)}>
									close
								</span>
							}
						</div>
					)}
					{storagesAllowed.length > 0 && allowEdit &&
						<CustomSelect options={storagesAllowed} name="storagesAllowed" currentValue=""
										  changeHandler={setStorage} placeholder="Добавить склад" eraseValue={true}/>
					}
				</div>
				
				<div className={cl.mapName}>
					<input type="search" value={search} onChange={doSearch} placeholder="Поиск поля"/>
				</div>
				
				{panelFields.map((field, index) =>
					<Fragment key={index.toString()}>
						{(index === 0 || panelFields[index - 1].storage !== field.storage) &&
							<div className={cl.mapName}>
								{field.storage}
							</div>
						}
						<div className={cl.field}>
							<div className={cl.fieldInfo}>
								<div className={cl.info}>
									<span className={cl.fieldName}>
										{field.isEditing
											? <input type="text" value={field.name}
														onChange={(e) => editFieldName(index, e.target.value)}
														placeholder="Название поля"/>
											: field.name
										}
										{!allowEdit &&
											<span className="material-icons" title="Открыть"
													onClick={() => navigate("/map/" + map.id + "/fields/" + field.id)}>
												launch
											</span>
										}
									</span>
									<span>Длина: {field.length}м</span>
									{allowEdit &&
										<>
											<span>Склад: {field.storage}</span>
											{field.isEditing &&
												<CustomSelect options={storages.filter(st => st.name !== field.storage)}
																  name="storages" currentValue="" currentIndex={index}
																  changeHandler={setFieldStorage} placeholder="Изменить склад"
																  eraseValue={true}/>
											}
										</>
									}
								</div>
							</div>
							
							<div className={cl.controls}>
								{allowEdit &&
									<>
										<span className="material-icons roundIcon dangerIcon" title="Удалить"
												onClick={() => removeField(index)}>
											close
										</span>
										<span className={"material-icons roundIcon " + (field.isEditing ? "successIcon" : "")}
												title="Редактировать" onClick={() => setEdit(index)}>
											{field.isEditing ? "done" : "edit"}
										</span>
									</>
								}
							</div>
						</div>
					</Fragment>
				)}
				
				{allowEdit &&
					<div className={cl.dangerZone}>
						<ButtonComponent type="danger" onClick={deleteMap}>Удалить карту</ButtonComponent>
					</div>
				}
			</div>
		</div>
	);
};

export default MapPanel;
import cl from "styles/pages/cabinet/settings.module.scss";
import Input from "components/input/InputComponent";
import Button from "components/ButtonComponent";
import {useEffect, useState} from "react";
import axios from "axios";


const Settings = ({children, alert, setCrumbs}) => {
	const [user, setUser] = useState({});
	
	// Загрузка данных
	useEffect(() => {
		axios.get("user/getSettings").then(response => {
			setUser(response.data.result);
		}).catch(() => {
			alert("Ошибка соединения с сервером", "danger");
		});
	}, []);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([{name: "Личный кабинет", url: "/cabinet"}, {name: "Настройки профиля", url: "/cabinet/settings"}]);
	}, []);
	
	// Изменение контактных данных
	const changeSetting = (name, data) => {
		const copy = Object.assign({}, user);
		copy[name] = data;
		setUser(copy);
	}
	
	// Отправка контактных данных
	const sendSettings = () => {
		// Проверка ввода данных
		if(user.last_name.length < 2) return;
		if(user.first_name.length < 2) return;
		if(user.email.length < 2) return;
		if(user.password && user.password !== user.passwordRepeat) return;
		
		axios.post("user/setSettings", user).then(() => {
			alert("Успешно сохранено", "success");
		}).catch(() => {
			alert("Ошибка соединения с сервером", "danger");
		});
	}
	
	return (
		<>
			{children}
			
			
				<title>Личный кабинет | настройки профиля</title>
			
			
			<div className="cabinetMain">
				<div className={cl.main + " container"}>
					<h2/>
					
					<form>
						<Input type="text" label="Фамилия" name="last_name" placeholder="введите вашу фамилию"
								 value={user.last_name} setValue={changeSetting} required={true}
								 isError={false}/>
						<Input type="text" label="Имя" name="first_name" placeholder="введите ваше имя"
								 value={user.first_name} setValue={changeSetting} required={true}
								 isError={false}/>
						<Input type="text" label="Отчество" name="second_name" placeholder="введите ваше отчество"
								 value={user.second_name} setValue={changeSetting} required={false}
								 isError={false}/>
						<Input type="email" label="Email" name="email" placeholder="введите email"
								 value={user.email} setValue={changeSetting} required={true}
								 isError={false}/>
						<Input type="password" label="Изменить пароль" name="password"
								 placeholder="для изменения введите новый пароль"
								 value={user.password} setValue={changeSetting} autocomplete="new-password"
								 isError={false}/>
						<Input type="password" label="Повтор пароля" name="passwordRepeat" placeholder="повторите пароль"
								 value={user.passwordRepeat} setValue={changeSetting} autocomplete="new-password"
								 isError={false}/>
						
						<div className="row">
							<div className="col-sm-6">
								<Button type="default" onClick={() => window.open("/faq/cabinet", "_blank")}>Инструкция</Button>
							</div>
							<div className="col-sm-6">
								<Button type="accent" onClick={sendSettings}>Сохранить</Button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default Settings;
import cl from "styles/components/Input.module.scss";
import InputMask from "react-input-mask";

const InputComponent = ({
  type,
  label,
  name,
  placeholder,
  required = false,
  mask = "",
  value,
  setValue,
  currentIndex = -1,
  isError = false,
  autocomplete = "on",
  disabled = false,
  title = "",
  onKeyDown = function () {},
  onFocus = function () {},
  onBlur = function () {},
}) => {
  const onChange = (name, value) => {
    if (currentIndex === -1) setValue(name, value);
    else setValue(currentIndex, name, value);
  };

  // Удаление нулей при фокусе
  const zero = (e) => {
    if (e.target.value === "0") {
      if (currentIndex === -1) setValue(name, "");
      else setValue(currentIndex, name, "");
    }
    onFocus();
  };

  return (
    <label htmlFor={name} className={cl.inputLabel}>
      <span>
        {label}
        {required && <span className={cl.required}>*</span>}
      </span>
      {mask.length > 0 ? (
        <InputMask
          type={type}
          mask={mask}
          name={name}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          disabled={disabled}
          className={isError ? cl.error : ""}
          autoComplete={autocomplete}
          onKeyDown={onKeyDown}
          onFocus={zero}
          onBlur={onBlur}
          title={title}
        />
      ) : type === "file" ? (
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          required={required}
          onChange={(e) => onChange(name, e.target.files[0])}
          disabled={disabled}
          className={isError ? cl.error : ""}
          autoComplete={autocomplete}
          onKeyDown={onKeyDown}
          onFocus={zero}
          onBlur={onBlur}
          title={title}
        />
      ) : (
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          disabled={disabled}
          className={isError ? cl.error : ""}
          autoComplete={autocomplete}
          onKeyDown={onKeyDown}
          onFocus={zero}
          onBlur={onBlur}
          title={title}
        />
      )}
    </label>
  );
};

export default InputComponent;

import { USER_SETTINGS } from "constants/settings";

const getToken = () => {
  const user = JSON.parse(localStorage.getItem("token") || "{}");
  return user.access_token;
};

const setToken = (data) => {
  return localStorage.setItem("token", JSON.stringify(data));
};

const removeToken = () => {
  try {
    localStorage.removeItem("token");
  } catch(e) {
    console.log(e)
  }
};

const getUser = () => {
  const user = getToken() ? JSON.parse(atob(getToken().split(".")[1])) : {};
  const accessLevel = user.level ? parseInt(atob(user.level)) : null;
  return {
    ...user,
    accessLevel,
  };
};

const getUserSetting = (key) => {
  const settings = JSON.parse(localStorage.getItem("settings") || "{}");
  return settings[key] ?? USER_SETTINGS[key];
};

const setUserSetting = (key, val) => {
  const settings = JSON.parse(localStorage.getItem("settings") || "{}");
  settings[key] = val;
  localStorage.setItem("settings", JSON.stringify(settings));
};

export {
  getToken,
  setToken,
  removeToken,
  getUser,
  getUserSetting,
  setUserSetting,
};

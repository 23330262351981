import cl from "styles/components/cabinet/UsersTable.module.scss";

import {useContext, useEffect, useState} from "react";
import Button from "components/ButtonComponent";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSupplies } from "redux/slices/businessSlice";

import { AppContext } from "providers/AppContextProvider";
import { loadingStatus } from "helpers/fetcher";
import { getUser } from "helpers/storage";
import { inventory, archive, faq } from "constants/routes";



const Index = () => {
	const navigate = useNavigate();
	const { search } = useLocation();

	const dispatch = useDispatch();
	const { supplies } = useSelector((state) => state.business);
	
	const { setCrumbs, setMenuCondition } = useContext(AppContext);	
	// Состояние с формами
	const [forms, setForms] = useState([]);
	
	useEffect(() => {
		// Сброс условия меню
		setMenuCondition({});
	}, [search]);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "Поступления", url: ""}
		]);
	}, []);

	useEffect(() => {
		dispatch(fetchSupplies());
	}, []);

	return (
		<>
      <title>Поступления</title>

			{!supplies.data.length && <p>&nbsp; Новых поступлений нет</p>}
			
			<table className={cl.usersTable}>
				<thead>
					<tr>
						<th colSpan="3"/>
						<th>
							<Button type="white"
									  onClick={() => navigate("/archive/supplies")}>
								Архив
							</Button>
						</th>
						<th>
							<Button type="white" onClick={() => window.open("/faq/supply")}>
								Инструкция
							</Button>
						</th>
					</tr>
					<tr>
						<th>№</th>
						<th>Тип</th>
						<th>Дата</th>
						<th>Склад</th>
						<th/>
					</tr>
				</thead>
				<tbody>
					{supplies.data.map((form, index) =>
						<tr key={index.toString()}>
							<td>
								<span>{form.document_number}</span>
							</td>
							<td>
								<span>Поступление</span>
							</td>
							<td>
								<span>{form.document_date}</span>
							</td>
							<td>
								<span>{form.storage ? form.storage : "НЕ ВЫБРАНО"}</span>
							</td>
							<td>
								<span className={cl.icon + " material-icons"} title="Посмотреть"
										onClick={() => navigate("/supplies/" + form.supply_id)}>
									visibility
								</span>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</>
	);
}

// export async function getServerSideProps(){
// 	const apiToken = "Bearer ~ybqF7}S9G9a5@8}aIex";
	
// 	const formsResponse = await axios.get("businessRu/getSupplies", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const Forms = formsResponse.data.result;
	
// 	return {props: {Forms}}
// }

export default Index;
import cl from "styles/components/cabinet/UsersTable.module.scss";

import axios from "axios";
import Button from "components/ButtonComponent";
import InputDate from "components/input/InputDate";
import {useState} from "react";
import { useNavigate } from "react-router-dom";

const HistoryTable = ({alert, userLevel, history, setHistory}) => {
	const navigate = useNavigate();
	const router = {
		pathname: window.location.pathname,
		push: (p) => navigate(p),
		query: { type: (new URLSearchParams(window.location.search)).get("type") },
	};
	
	// Конечная дата архивации
	const [endDate, setEndDate] = useState("");
	
	// Метод изменения конечной даты
	const changeEndDate = (name, value) => {
		setEndDate(value);
	}
	
	// Удаление архива
	const remove = (index) => {
		const copy = Object.assign([], history);
		
		alert("Удалить архив?", "danger", 10000, [
			{
				text: "Да",
				handler: function(){
					axios.delete("history/remove/" + copy[index].id).then(() => {
						alert("", "default", 1);
						copy.splice(index, 1);
						setHistory(copy);
					}).catch(() => {
						alert("Ошибка удаления", "danger");
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	const archive = () => {
		// Запрет пустой даты
		if(endDate === ""){
			alert("Введите конечную дату", "danger");
			return false;
		}
		
		// Выдача уведомления с вопросом и отправка запроса
		alert("Архивировать историю?", "default", 10000, [
			{
				text: "Да",
				handler: function(){
					axios.post("history/archive", {endDate: endDate}).then(() => {
						axios.get("history/getArchives").then(response => {
							setHistory(response.data.result);
							alert("История успешно архивирована", "success");
						});
					}).catch(() => {
						alert("Ошибка архивирования", "danger");
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	return (
		<>
			{userLevel() === 3 &&
				<div style={{width: "300px", margin: "16px"}}>
					<p style={{margin: "0 0 8px 0", padding: "0"}}>
						Конечная дата архивации:
						<InputDate name="endDate" currentValue={endDate} changeHandler={changeEndDate}/>
					</p>
					<Button type="accent" onClick={archive}>Архивировать текущий сезон</Button>
				</div>
			}
			
			<table className={cl.usersTable}>
				<thead>
					<tr>
						<th>Архивы</th>
						<th/>
						{userLevel() === 3 && <th/>}
					</tr>
				</thead>
				<tbody>
					{history.map((h, index) =>
						<tr key={index.toString()}>
							<td>
								<span>{h.dates}</span>
							</td>
							<td>
							<span className={cl.icon + " material-icons"} title="Посмотреть"
									onClick={() => router.push("/archive/history/" + h.id)}>
								visibility
							</span>
							</td>
							{userLevel() === 3 &&
								<td>
								<span className={cl.icon + " " + cl.remove + " material-icons"} title="Удалить"
										onClick={() => remove(index)}>
									close
								</span>
								</td>
							}
						</tr>
					)}
				</tbody>
			</table>
		</>
	);
};

export default HistoryTable;
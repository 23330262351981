import cl from "styles/components/Alert.module.scss"

/**
 * buttons = [{text: "", handler: function(){...}}, ...]
 */
const AlertComponent = ({children, alertType, alertIsActive, buttons, inProcess = false}) => {
	const classes = [cl.alertContainer, cl[alertType]];
	if(alertIsActive) classes.push(cl.active);
	
	return (
		<div className={classes.join(" ")}>
			{inProcess
				? <span className="material-icons spinner">scatter_plot</span>
				: <>
					{children}
					
					<div className={cl.btns}>
						{buttons.length > 0 && buttons.map(bt =>
							<span onClick={bt.handler}>{bt.text}</span>
						)}
					</div>
				</>
			}
		</div>
	);
};

export default AlertComponent;
const maps = {
  ALL: "/map/getMaps",
  MAP: "/map/getMap",
  FIELDS: "/map/getAllFields",
  ADD: "/map/add",
  DELETE_FIELD: "/map/deleteField",
  ADDRESSES: "/map/getAllAddresses",
};

const fields = {
  HEADS: "/fields/updateFieldHead",
  DATA_LINE: "/fields/updateFieldDataLine",
  DELETE_DATA: "/fields/deleteFieldData",
  PRINT_TABLE: "/fields/printFieldTable",
};

const user = {
  LOGIN: "/auth/getToken",
  INFO: "/user/info",
};

const journals = {
  AMOUNT_DATA: "/journals/amountData",
  AMOUNT_ALL: "/journals/amountAll",
  JOURNAL_NAME: "/journals/journalName",
  SPREAD_DATA: "/journals/spreadData",
};

const inventory = {
  ALL: "/inventory/all",
  POST_FILE: "/inventory/getAmounts",
  ITEMS: "/inventory/getItems",
};

const business = {
  GROUPS: "/businessRu/getGroups",
  GOODS: "/businessRu/getGoods",
  STORES: "/businessRu/getStores",
  SUPPLIES: "/businessRu/getSupplies",
  SUPPLY: "/businessRu/getSupply",
};

const doc = {
  REALIZATIONS: "/realizations/getRealizations",
  FACTORIES: "/factory/all",
  FACTORY: "/factory/getFactory",
}

export {
  maps,
  fields,
  user,
  journals,
  inventory,
  business,
  doc,
};

import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { AppContext } from "providers/AppContextProvider";
import A from "components/A";
import { fetchInfo, logOut } from "redux/slices/userSlice";
import { fetchMapFields } from "redux/slices/mapSlice";
import { getUser } from "helpers/storage";
import { auth } from "constants/routes";
import cl from "styles/components/MainContainer/Sidebar.module.scss";

const SidebarComponent = () => {
  // Ссылки для меню
  const [links, setLinks] = useState([]);

  // Состояния полей
  const [fields, setFields] = useState([]);
  const [search, setSearch] = useState("");

  // Состояние интервала для запроса полей
  const [fieldsInterval, setFieldInterval] = useState(null);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { alert, menuCondition, sidenavIsMinified, handleToggleSidenavIsMinified } = useContext(AppContext);
  const {
    info: { name: userName },
  } = useSelector((state) => state.user);
  const { mapFields } = useSelector((state) => state.map);

  // // Обработка кнопки выхода
  const logout = () => {
    dispatch(logOut());
    navigate(auth.LOGIN);
  };

  // Поиск по названию поля
  const doSearch = (e) => {
    const copy = Object.assign([], links);
    setSearch(e.target.value);
    if (e.target.value.trim() === "") copy[1].dropdown = fields;
    else
      copy[1].dropdown = fields.filter((fl) =>
        fl.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
  };

  // Сборка меню
  useEffect(() => {
    // if (mapFields.status !== loadingStatus.SUCCEEDED) {
    //   return;
    // }

    // const copy = Object.assign([], links);
    const copy = [];

    copy.push({ name: "Главная", url: "/", icon: "home" });
    copy.push({
      name: "Журналы",
      url: "#",
      icon: "library_books",
      dropdown: [],
    });
    copy[1].dropdown.push({ name: "Сводный журнал", url: "/journals/amount" });

    copy.push({
      name: "Новые документы",
      url: "#",
      icon: "folder_open",
      dropdown: [
        {
          name: "Инвентаризация",
          url: "/inventory",
        },
        {
          name: "Поступление",
          url: "/supplies",
        },
        {
          name: "Отгрузка",
          url: "/realizations",
        },
        {
          name: "Списание",
          url: "/form?type=Списание",
        },
        {
          name: "Оприходование",
          url: "/form?type=Оприходование",
        },
        {
          name: "Перемещение",
          url: "/form?type=Перемещение",
        },
        {
          name: "Производство",
          url: "/factory",
        },
      ],
    });

    copy.push({
      name: "Отчеты по движению ТМЦ",
      url: "#",
      icon: "list",
      dropdown: [
        { name: "Архивы", url: "/archive" },
        { name: "История", url: "/history/select" },
        { name: "Сверка складской базы и Агроплана", url: "/journals/spread" },
      ],
    });

    // Сборка личного кабинета
    copy.push({
      name: "Личный кабинет",
      url: "#",
      icon: "account_circle",
      dropdown: [{ name: "Настройки профиля", url: "/cabinet/settings" }],
    });
    if (getUser().accessLevel > 2)
      copy[copy.length - 1].dropdown.push({
        name: "Сотрудники",
        url: "/cabinet/users",
      });

    copy.push({
      name: "Инструкции",
      url: "/faq",
      icon: "question_answer",
    });

    // Сборка всех полей
    [...mapFields.data]
      .sort((a, b) => {
        if (a.sort_name === b.sort_name) return 0;
        else return a.sort_name > b.sort_name ? 1 : -1;
      })
      .forEach((field) => {
        copy[1].dropdown.push({
          name: field.name,
          url: "/map/" + field.map_id + "/fields/" + field.map_field_id,
          descr:
            field.total !== null && field.total !== "0"
              ? field.total + "шт."
              : "без товаров",
        });
      });

    setLinks(copy);
    setFields(copy[1].dropdown);
  }, [mapFields]);

  // Интервальная сборка всех полей, если открыты разделы карты
  useEffect(() => {
    if (!pathname.includes("map") || !links.length) {
      if (fieldsInterval !== null) {
        clearInterval(fieldsInterval);
        setFieldInterval(null);
      }
      return;
    }

    const fi = setInterval(() => {
      dispatch(fetchMapFields());
    }, 5000);
    setFieldInterval(fi);
  }, [pathname]);

  useEffect(() => {
    dispatch(fetchInfo());
    dispatch(fetchMapFields());
  }, []);

  return (
    <div className={cl.sidebar + (sidenavIsMinified ? " " + cl.minified : "")}>
      <ul className={cl.menu}>
        <li key="08374">
          <a href="#" onClick={handleToggleSidenavIsMinified}>
            <span className="material-icons">menu</span>
          </a>
        </li>
        {links.map((link, index) => (
          <li key={index.toString()}>
            <A href={link.url} alert={alert} condition={menuCondition}>
              <span className="material-icons">{link.icon}</span>
              <span className={cl.linkName}>{link.name}</span>

              {link.dropdown && (
                <>
                  <span className={"material-icons " + cl.dropdownIcon}>
                    chevron_right
                  </span>
                  <div className={cl.dropdown}>
                    <ul className={cl.dropdownItems}>
                      {link.name === "Журналы" && (
                        <li>
                          <input
                            type="text"
                            value={search}
                            onChange={doSearch}
                            placeholder="Поиск поля"
                          />
                        </li>
                      )}
                      {link.dropdown.map((dropdownLink) => (
                        <li key={dropdownLink.url}>
                          <A
                            href={dropdownLink.url}
                            alert={alert}
                            condition={menuCondition}
                          >
                            {dropdownLink.name}
                            {dropdownLink.descr
                              ? ", " + dropdownLink.descr
                              : ""}
                          </A>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </A>
          </li>
        ))}

        <li
          className={cl.minify + " " + cl.user}
          onClick={logout}
          title="Выйти"
        >
          <span className={cl.linkName}>{userName}</span>
          <span className="material-icons">logout</span>
        </li>
      </ul>
    </div>
  );
};

export default SidebarComponent;

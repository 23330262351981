import cl from "styles/components/cabinet/UsersTable.module.scss";

import {useContext, useEffect, useMemo, useState} from "react";
import Button from "components/ButtonComponent";
import Indicator from "../../components/Indicator";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "providers/AppContextProvider";
import { fetchFieldForms } from "redux/slices/fieldSlice";
import { getUser } from "helpers/storage";

const Index = () => {
	const navigate = useNavigate();
	const { search } = useLocation();

	const dispatch = useDispatch();
	const { fieldForms } = useSelector((state) => state.field);
  
	const { setCrumbs, setMenuCondition } = useContext(AppContext);
	
	// Состояние с формами
	const [forms, setForms] = useState([]);
	
  	const searchType = useMemo(() => (new URLSearchParams(search)).get("type"), [search]);
  
	// Кнопки новой формы в зависимости от типа
	const typesButtons = {
		"Оприходование": "Новое оприходование",
		"Списание": "Новое списание",
		"Перемещение": "Новое перемещение"
	};
	
	// Соответствие ссылок для FAQ
	const faqButtons = {
		"Оприходование": "posting",
		"Списание": "charge",
		"Перемещение": "shifting"
	};

  useEffect(() => {
		// Сброс условия меню
		setMenuCondition({});
		
		// Фильтрация форм по необходимым параметрам
		const copy = fieldForms.data.filter(form => {
			return form.type === searchType
		});
		setForms(copy);
	}, [searchType, fieldForms.data]);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: searchType, url: ""}
		]);
	}, []);

	useEffect(() => {
		dispatch(fetchFieldForms());
	}, []);
  
	
	return (
		<>
			
				<title>{searchType}</title>
			
			<div style={{padding: "16px"}}>
				&nbsp;
				<Indicator type="danger"/> - {searchType} создано &nbsp;
				<Indicator type="success"/> - {searchType} проведено в Системе
			</div>
			
			<table className={cl.usersTable}>
				<thead>
					<tr>
						<th colSpan="2">
							{getUser().accessLevel > 1 &&
								<Button type="accent" onClick={() => navigate("/form/new?type=" + searchType)}>
									{typesButtons[searchType]}
								</Button>
							}
						</th>
						<th colSpan="2">
							<Button type="white"
									  onClick={() => navigate("/archive/form?type=" + searchType)}>
								Архив
							</Button>
						</th>
						<th colSpan="2">
							<Button type="white" onClick={() => window.open("/faq/" + faqButtons[searchType])}>
								Инструкция
							</Button>
						</th>
					</tr>
					<tr>
						<th>Системный №</th>
						<th>Тип</th>
						<th>Дата в Системе</th>
						<th>Склад</th>
						<th/>
						<th/>
					</tr>
				</thead>
				<tbody>
					{forms.map((form, index) =>
						<tr key={index.toString()}>
							<td>
								<span>{form.document_number}</span>
							</td>
							<td>
								<span>{form.type}</span>
							</td>
							<td>
								<span>{form.document_date}</span>
							</td>
							<td>
								<span>{form.storage}</span>
							</td>
							<td>
								<span className={cl.icon + " material-icons"} title="Посмотреть"
										onClick={() => navigate("/form/" + form.id + "?type=" + searchType)}>
									visibility
								</span>
							</td>
							<td>
								<Indicator type={form.provided === 1 ? "success" : "danger"}/>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</>
	)
}

export default Index;
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AppContext } from "providers/AppContextProvider";
import SpinPlaceholderComponent from "components/preloader/SpinPlaceholderComponent";
import MapComponent from "components/map/Map";
import { fetchMap } from "redux/slices/mapSlice";
import { loadingStatus } from "helpers/fetcher";

const Index = ({ children }) => {
	
  const { setCrumbs } = useContext(AppContext);
	const dispatch = useDispatch();
	const { map } = useSelector((state) => state.map);
  const { id } = useParams();

  useEffect(() => {
		setCrumbs([
			{name: map.data.name, url: "/map/" + id}
		]);
	}, [map.data, id]);

	useEffect(() => {
		dispatch(fetchMap(id));
	}, [id]);
	
  console.log(map.status)
	return (
		<>
      <title>{map.name || ""}</title>
			
			{children}
			
      {[loadingStatus.IDLE, loadingStatus.LOADING].includes(map.status) ?
        <SpinPlaceholderComponent isActive isSmall /> :
        <MapComponent map={map.data}/>
      }
		</>
	);
};

export default Index;
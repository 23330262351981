import { Fragment } from "react";
import cl from "styles/components/Card.module.scss";

const Card = ({name, descr = "", image, buttons = []}) => {
	return (
		<div className={cl.card}>
			<div className={cl.img} style={{backgroundImage: "url(" + image + ")"}}>
				<div className={cl.imgCovered}>
					{buttons.map((button, index) =>
						<Fragment key={index.toString()}>
							{button !== false &&
								<span className={cl.buttonText} onClick={button.handler}>
									{button.text}
								</span>
							}
						</Fragment>
					)}
				</div>
			</div>
			<span className={cl.name}>{name} {descr}</span>
		</div>
	);
};

export default Card;
// TODO: избавиться от этого компонента
import { useNavigate } from "react-router-dom";

const A = ({ children, href, classes, alert, condition, target = "" }) => {
	const navigate = useNavigate();
	
	// Кастомная функция перехода на страницу
	const go = (e) => {
		e.preventDefault();
		if(href === "#") return;
		
		// Если задан таргет, переход с ним
		if(target.length > 0) window.open(href, target);
		
		// Если не задано условие - переход по ссылке, иначе вывод предупреждения
		else if(!condition.text) navigate(href);
		else{
			// Обертка функций кнопок в другую функцию для передачи URL
			condition.buttons.map(btn => {
				let handler = btn.handler;
				btn.handler = () => handler(href);
			});
			
			// Вызов уведомления с условием
			alert(condition.text, condition.type, condition.duration, condition.buttons);
		}
	}
	
	return (
    <a className={classes} onClick={go}>{children}</a>
	);
};

export default A;
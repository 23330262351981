import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { business } from "constants/endpoints";

const fetchGroups = createAsyncThunk("businessRu/getGroups", async () => {
  const response = await fetcher(business.GROUPS);
  return response.json();
});

const fetchGoods = createAsyncThunk("businessRu/getGoods", async () => {
  const response = await fetcher(business.GOODS);
  return response.json();
});

const fetchStores = createAsyncThunk("businessRu/getStores", async () => {
  const response = await fetcher(business.STORES);
  return response.json();
});

const fetchSupplies = createAsyncThunk("businessRu/getSupplies", async (q = "") => {
  const response = await fetcher(`${business.SUPPLIES}${q}`);
  return response.json();
});

const fetchSupply = createAsyncThunk("businessRu/getSupply/id", async (id) => {
  const response = await fetcher(`${business.SUPPLY}/${id}`);
  return response.json();
});

const initialState = {
  groups: {
    status: loadingStatus.IDLE,
    data: [],
    error: null,
  },
  goods: {
    status: loadingStatus.IDLE,
    data: {
      goods: [],
      articles: [],
    },
  },
  stores: {
    status: loadingStatus.IDLE,
    data: [],
  },
  supplies: {
    status: loadingStatus.IDLE,
    data: [],
  },
  supply: {
    status: loadingStatus.IDLE,
    data: {},
  },
};

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGroups.pending, (state) => {
        state.groups.status = loadingStatus.LOADING;
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.groups.status = loadingStatus.SUCCEEDED;
        state.groups.data = action.payload.result;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.groups.status = loadingStatus.FAILED;
        state.groups.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(fetchGoods.pending, (state) => {
        state.goods.status = loadingStatus.LOADING;
      })
      .addCase(fetchGoods.fulfilled, (state, action) => {
        state.goods.status = loadingStatus.SUCCEEDED;
        state.goods.data = action.payload.result;
      })

      .addCase(fetchStores.pending, (state) => {
        state.stores.status = loadingStatus.LOADING;
      })
      .addCase(fetchStores.fulfilled, (state, action) => {
        state.stores.status = loadingStatus.SUCCEEDED;
        state.stores.data = action.payload.result;
      })

      .addCase(fetchSupplies.pending, (state) => {
        state.supplies.status = loadingStatus.LOADING;
      })
      .addCase(fetchSupplies.fulfilled, (state, action) => {
        state.supplies.status = loadingStatus.SUCCEEDED;
        state.supplies.data = action.payload.result;
      })

      .addCase(fetchSupply.pending, (state) => {
        state.supply.status = loadingStatus.LOADING;
      })
      .addCase(fetchSupply.fulfilled, (state, action) => {
        state.supply.status = loadingStatus.SUCCEEDED;
        state.supply.data = action.payload.result;
      })
  }
});

export default businessSlice.reducer;

export {
  fetchGroups,
  fetchGoods,
  fetchStores,
  fetchSupplies,
  fetchSupply,
};

import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Form from "components/form/Form";
import { getUser } from "helpers/storage";
import { fetchGroups, fetchStores } from "redux/slices/businessSlice";
import { fetchMapAddresses } from "redux/slices/mapSlice";
import { AppContext } from "providers/AppContextProvider";

const New = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const { alert, setCrumbs, setMenuCondition } = useContext(AppContext);
  const dispatch = useDispatch();
  const { groups, stores } = useSelector((state) => state.business);
  const { mapAddresses } = useSelector((state) => state.map);

  // Перенаправление на главную, если нет доступа
  useEffect(() => {
    if (getUser().accessLevel < 2) navigate("/");
  }, []);

  // Запись хлебных крошек
  useEffect(() => {
    setCrumbs([
      { name: type, url: "/form?type=" + type },
      { name: "Новый документ", url: "" },
    ]);
  }, []);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchStores());
    dispatch(fetchMapAddresses());
  }, []);

  return (
    <>
      <title>Новое {type.toLowerCase()}</title>

      <Form
        alert={alert}
        setMenuCondition={setMenuCondition}
        groups={groups.data}
        type={type}
        userLevel={() => getUser().accessLevel}
        form={{ provided: 0 }}
        storages={stores.data}
        AddressList={mapAddresses.data}
      />
    </>
  );
};

export default New;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Indicator from "components/Indicator";
import ButtonComponent from "components/ButtonComponent";
import { fetchInventories } from "redux/slices/inventorySlice";
import { loadingStatus } from "helpers/fetcher";
import { getUser } from "helpers/storage";
import { inventory, archive, faq } from "constants/routes";
import cl from "styles/components/cabinet/UsersTable.module.scss";

const Inventory = ({ setCrumbs = () => {} }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { inventories } = useSelector((state) => state.inventory);

  // Запись хлебных крошек
  useEffect(() => {
    setCrumbs([{name: "Инвентаризация", url: ""}]);
    dispatch(fetchInventories());
  }, []);
  
  return (
    <>
			
      <title>Инвентаризация</title>
			
      {inventories.status === loadingStatus.LOADING && <div>Loading...</div>}
			<div style={{padding: "16px"}}>
				&nbsp;
				<Indicator type="danger"/> - Инвентаризация создана &nbsp;
				<Indicator type="warning"/> - Инвентаризация редактируется &nbsp;
				<Indicator type="success"/> - До проведения в Системе &nbsp;
				<Indicator type="blued"/> - После проведения в Системе
			</div>
			
			<table className={cl.usersTable}>
				<thead>
					<tr>
						<th colSpan="2">
							{getUser().accessLevel > 1 &&
								<ButtonComponent type="accent" onClick={() => navigate(inventory.ADD)}>
									Новая инвентаризация
								</ButtonComponent>
							}
						</th>
						<th/>
						<th>
							<ButtonComponent type="white" onClick={() => navigate(archive.INVENTORY)}>
								Архив
							</ButtonComponent>
						</th>
						<th colSpan="2">
							<ButtonComponent type="white" onClick={() => window.open(faq.INVENTORY)}>
								Инструкция
							</ButtonComponent>
						</th>
					</tr>
					<tr>
						<th>№</th>
						<th>Дата инвентаризации</th>
						<th>Дата в Системе</th>
						<th>Последнее сохранение</th>
						<th/>
						<th/>
					</tr>
				</thead>
				<tbody>
					{inventories.data.map((inv, index) =>
						<tr key={index.toString()}>
							<td>
								<span>{inv.before.document_number}</span>
								{inv.after &&
									<><br/><span>{inv.after.document_number}</span></>
								}
							</td>
							<td>
								<span>{inv.before.date}</span>
								{inv.after &&
									<><br/><span>{inv.after.date}</span></>
								}
							</td>
							<td>
								<span>{inv.before.date_current}</span>
								{inv.after &&
									<><br/><span>{inv.after.date_current}</span></>
								}
							</td>
							<td>
								<span>{inv.before.last_edit}</span>
								{inv.after &&
									<><br/><span>{inv.after.last_edit}</span></>
								}
							</td>
							<td>
								<span className={cl.icon + " material-icons"} title="Посмотреть"
                  onClick={() => {
                    navigate(`/inventory/edit/${inv.before.id}`);
                    if(inv.after) window.open(`/inventory/edit/${inv.after.id}`, "_blank");
                  }}
                >
									visibility
								</span>
							</td>
							<td>
								<Indicator
									type={inv.before.provided === 1 ? "success" : inv.before.saved === 1 ? "warning" : "danger"}/>
								{inv.after &&
									<><br/><Indicator type="blued"/></>
								}
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</>
  );
};

export default Inventory;

import {useEffect, useRef} from "react";
import { useNavigate } from "react-router-dom";
import { MapContainer, TileLayer, Polygon, Tooltip, ZoomControl } from "react-leaflet";
import axios from "axios";
import html2canvas from "html2canvas";

import MapPanel from "./MapPanel";
import "leaflet/dist/leaflet.css";

const Map = ({ map }) => {
	const navigate = useNavigate();

	// Область для скриншота
	const toScreenShot = useRef();
	
	// Отправка скриншота
	useEffect(() => {
		setTimeout(() => {
			screenShot();
		}, 5000);
	}, []);
	
	// Метод для отправки скриншота карты
	const screenShot = async() => {
		try {
			const canvas = await html2canvas(toScreenShot.current, {useCORS: true}).then(canv => {
				const image = canv.toDataURL("image/png", 1.0);
				axios.post(process.env.REACT_APP_SERVER_ENTRYPOINT + "/map/editThumb/" + map.id, {thumb: image});
			});
		} catch (e) {
			console.log(e);
		}
	}
	
	// Перенаправление на страницу поля
	const fieldMove = (id) => {
		navigate("/map/" + map.id + "/fields/" + id);
	}
	
	return (
		<>
			<MapPanel map={map} fields={map.fields !== null ? map.fields : []}/>
			
			<div ref={toScreenShot}>
				<MapContainer center={[map.center_x, map.center_y]} zoom={map.zoom} scrollWheelZoom={true}
								  doubleClickZoom={true} preferCanvas={true} zoomControl={false} attributionControl={false}>
					<TileLayer url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
								  subdomains={["mt0", "mt1", "mt2", "mt3"]}/>
					<ZoomControl position="topright"/>
					
					{map.fields !== null && map.fields.map(field =>
						<Polygon key={field.name} pathOptions={{color: "black"}} positions={field.coords}
									eventHandlers={{click: () => fieldMove(field.id)}}>
							<Tooltip direction="center" opacity={1} permanent>
								{field.name}
							</Tooltip>
						</Polygon>
					)}
				</MapContainer>
			</div>
		</>
	);
};

export default Map;
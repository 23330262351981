import cl from "styles/pages/index.module.scss";
import Card from "components/CardComponent";
import {useContext, useEffect} from "react";
import Button from "components/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "providers/AppContextProvider";

const Index = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { setMenuCondition, setCrumbs } = useContext(AppContext);
  
	// const { factories } = useSelector((state) => state.doc);

	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([{name: "Архивы", url: ""}]);
	}, []);
	
	return (
		<>
			
				<title>Архивы</title>
			
			
			
			<div className="container" style={{marginTop: "2rem", marginLeft: "6rem"}}>
				<div className="row">
					<div className="col-sm-3">
						<Button type="default" onClick={() => window.open("/faq/archive", "_blank")}>Инструкция</Button>
					</div>
				</div>
			</div>
			
			<div className={cl.cardContainer + " container"}>
				<div className="row">
					<div className="col-sm-4" style={{marginTop: "2rem"}}>
						<Card name="История (архив сезонов)" image="/img/history/history-icon.svg" buttons={[
							{
								text: "Открыть",
								handler: function(){
									navigate("/archive/history");
								}
							}
						]}/>
					</div>
					
					<div className="col-sm-4" style={{marginTop: "2rem"}}>
						<Card name="Движение товаров" image="/img/forms/form-icon.svg" buttons={[
							{
								text: "Оприходования",
								handler: function(){
									navigate("/archive/form?type=Оприходование");
								}
							},
							{
								text: "Списания",
								handler: function(){
									navigate("/archive/form?type=Списание");
								}
							},
							{
								text: "Перемещения",
								handler: function(){
									navigate("/archive/form?type=Перемещение");
								}
							}
						]}/>
					</div>
					
					<div className="col-sm-4" style={{marginTop: "2rem"}}>
						<Card name="Поступления" image="/img/supplies/supply-icon.svg" buttons={[
							{
								text: "Открыть",
								handler: function(){
									navigate("/archive/supplies");
								}
							}
						]}/>
					</div>
					
					<div className="col-sm-4" style={{marginTop: "2rem"}}>
						<Card name="Отгрузки" image="/img/realizations/realization-icon.svg" buttons={[
							{
								text: "Открыть",
								handler: function(){
									navigate("/archive/realizations");
								}
							}
						]}/>
					</div>
					
					<div className="col-sm-4" style={{marginTop: "2rem"}}>
						<Card name="Коррекции" image="/img/inventory/inventory-icon2.svg" buttons={[
							{
								text: "Открыть",
								handler: function(){
									navigate("/archive/form?correction=1");
								}
							}
						]}/>
					</div>
					
					<div className="col-sm-4" style={{marginTop: "2rem"}}>
						<Card name="Инвентаризации" image="/img/inventory/inventory-icon.svg" buttons={[
							{
								text: "Открыть",
								handler: function(){
									navigate("/archive/inventory");
								}
							}
						]}/>
					</div>
					
					<div className="col-sm-4" style={{marginTop: "2rem"}}>
						<Card name="Производство" image="/img/factory/factory-icon.svg" buttons={[
							{
								text: "Открыть",
								handler: function(){
									navigate("/archive/factory");
								}
							}
						]}/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Index;
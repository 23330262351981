import axios from "axios";
import React, {
  Fragment,
  useRef,
  useEffect,
  useState,
  useContext,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import cloneDeep from "lodash/cloneDeep";

import Input from "components/input/InputComponent";
import Button from "components/ButtonComponent";
import CustomSelect from "components/CustomSelect";
import Preloader from "components/preloader/SpinPlaceholderComponent";
import FilterInventory from "components/FilterInventory";
import Modal from "components/Modal";
import { getUser } from "helpers/storage";

import {
  fetchInventory,
  fetchAddresses,
  fetchFields,
} from "redux/slices/inventorySlice";
import { fetchGroups, fetchGoods } from "redux/slices/businessSlice";

import { AppContext } from "providers/AppContextProvider";
import { loadingStatus } from "helpers/fetcher";
import { inventory } from "constants/routes";

import cl from "styles/pages/[fieldId].module.scss";
import colors from "styles/pages/[boardId].module.scss";

const InventoryEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { alert, setCrumbs } = useContext(AppContext);
  const dispatch = useDispatch();
  const { inventory, addresses, fields } = useSelector(
    (state) => state.inventory
  );
  const { groups, goods } = useSelector((state) => state.business);

  const toScreenShot = useRef();

  // Данные с сервера
  const [items, setItems] = useState([]);

  // Классы таблицы
  const tableClasses = [
    cl.mainTable,
    cl.top0,
    cl.overflow,
    "table",
    "table-responsive",
  ];

  // Асинхронные данные из Бизнес.ру
  const [plantsNames, setPlantsNames] = useState([]);
  const [plantsArticles, setPlantsArticles] = useState([]);

  // Активность кнопки расчета отклонения
  const [activeTotalSpread, setActiveTotalSpread] = useState(false);

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  // Поле сортировки и направление
  const [sorted, setSorted] = useState("name");
  const [sortDir, setSortDir] = useState(true);

  // Состояния фильтра
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [dataBackup, setDataBackup] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);

  // Общее кол-во по позициям
  const [totals, setTotals] = useState({ uchet: 0, fact: 0 });

  // Состояния для примечаний
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState([]);
  const [modalIsActive, setModalIsActive] = useState(false);

  // Интервал автосохранения
  const autoSaveInterval = 300000;

  useEffect(() => {
    // Если доступ просмотровый, запрет редактирования
    if (getUser().accessLevel < 2) items[0].provided = 1;

    // Создание превью инвентаризации
    setTimeout(() => {
      screenShot();
    }, 5000);
  }, []);

  useEffect(() => {
    // Запрос наименований растений
    if (goods.data.goods.length > 0) {
      // goods.data.goods.sort((a, b) => {
      // 	if(a.name === b.name) return 0;
      // 	else return a.name > b.name ? 1 : -1;
      // });
      setPlantsNames(goods.data.goods);
    }
    if (goods.data.articles.length > 0) {
      setPlantsArticles(goods.data.articles);
    }
    console.log(goods.data)
  }, [goods.data]);

  useEffect(() => {
    const total = Object.assign({}, totals);
    total.uchet = 0;
    total.fact = 0;
    items.map((it) => {
      // Включение кнопки расчета отклонения
      if (
        parseInt(it.total_fact) - parseInt(it.total_uchet) !==
        parseInt(it.total_spread)
      )
        setActiveTotalSpread(true);

      // Подсчет общего кол-ва
      total.uchet += it.total_uchet;
      total.fact += it.total_fact;
    });
    setTotals(total);

    // Автосохранение
    for (let i = 1; i < 1000; i++) clearInterval(i);
    setInterval(() => {
      if (items[0]?.provided === 0) save();
    }, autoSaveInterval);
  }, [items]);

  // Запись хлебных крошек
  useEffect(() => {
    setCrumbs([
      { name: "Инвентаризация", url: "/inventory" },
      // TODO: треш полный
      // {
      // 	name: "№ " + items[0].document_number + (items[0].provided ? (items[0].is_copy > 0 ? " после" : " до") + " проведения в Системе" : ""),
      // 	url: ""
      // }
    ]);
  }, []);

  // Изменение данных в формах
  const onChange = (index, name, value) => {
    const copy = cloneDeep(items);
    copy[index.index]["datas"][index.i][name] = value;

    const uchet = isNaN(
      parseInt(copy[index.index]["datas"][index.i]["amount_uchet"])
    )
      ? 0
      : parseInt(copy[index.index]["datas"][index.i]["amount_uchet"]);
    const fact = isNaN(
      parseInt(copy[index.index]["datas"][index.i]["amount_fact"])
    )
      ? 0
      : parseInt(copy[index.index]["datas"][index.i]["amount_fact"]);
    copy[index.index]["datas"][index.i]["spread"] = fact - uchet;

    // Подсчет общего факта
    let factAll = 0;
    copy[index.index]["datas"].map((dt) => {
      factAll += isNaN(parseInt(dt.amount_fact)) ? 0 : parseInt(dt.amount_fact);
    });
    copy[index.index].fact = factAll;
    copy[index.index].total_fact = factAll;
    copy[index.index].spread =
      parseInt(factAll) - parseInt(copy[index.index].uchet);

    setItems(copy);
  };

  // Сохранение в файл
  const print = async () => {
    await axios.post("inventory/editFile", {
      inventory: items,
      filename: items[0].filename,
    });
  };

  // Создание файлов для Бизнес.ру
  const printBru = (type) => {
    axios
      .post("inventory/editFilesBru", {
        inventory: items,
      })
      .then((response) => {
        navigate(
          process.env.REACT_APP_SERVER_URL +
            "/FILES/" +
            response.data.files[type]
        ).then(() => navigate("/inventory/edit/" + items[0].inventory_id));
      });
  };

  // Обработчик кнопки загрузки
  const download = () => {
    let btns = [
      {
        text: "Системный",
        handler: function () {
          alert("", "default", 1);
          print().then(() =>
            navigate(
              process.env.REACT_APP_SERVER_URL + "/FILES/" + items[0].filename
            ).then(() => navigate("/inventory/edit/" + items[0].inventory_id))
          );
        },
      },
    ];
    if (items[0].is_copy !== 0) {
      btns = [
        ...btns,
        {
          text: "Факт",
          handler: function () {
            alert("", "default", 1);
            printBru("fact");
          },
        },
        {
          text: "Списание",
          handler: function () {
            alert("", "default", 1);
            printBru("charge");
          },
        },
        {
          text: "Оприходование",
          handler: function () {
            alert("", "default", 1);
            printBru("posting");
          },
        },
      ];
    }

    alert("Вариант скачивания:", "default", 10000, btns);
  };

  // Сохранение данных
  const save = async (alertAllowed = true) => {
    // Проверка ввода при проведении
    let error = false;
    if (!items[0].employee || items[0].employee === "") {
      error = true;
      alert("Укажите бригадира!", "danger");
    }
    if (!alertAllowed) {
      items.map((it) => {
        it.datas.map((dt) => {
          if (dt.address_fact.trim() !== "" && dt.amount_fact.trim() === "") {
            error = true;
            alert("Не введено кол-во для: " + it.name, "danger");
          }
          if (dt.address_fact.trim() === "" && dt.amount_fact.trim() === "") {
            error = true;
            alert("Введите позицию для: " + it.name, "danger");
          }
        });
      });
    }
    if (error) {
      setIsPreloaderActive(false);
      return false;
    }

    if (alertAllowed) setIsPreloaderActive(true);

    // Создание превью инвентаризации
    await screenShot();

    // Редактирование файла
    await print();

    return await axios
      .post("inventory/save", {
        inventory: isFilterActive ? dataBackup : items,
        comment: comment,
      })
      .then(() => {
        if (alertAllowed) {
          alert("Успешно сохранено", "success");
          setIsPreloaderActive(false);
        }
        return true;
      })
      .catch((error) => {
        if (error.response.status === 500) alert("Ошибка сохранения", "danger");
        else {
          alert(error.response.data.message, "danger");
          items[error.response.data.index.i].isError = true;
          setTimeout(() => {
            items[error.response.data.index.i].isError = false;
          }, 3000);
        }
        setIsPreloaderActive(false);
        return false;
      });
  };

  // Удаление инвентаризации
  const remove = () => {
    alert("Удалить инвентаризацию?", "danger", 10000, [
      {
        text: "Да",
        handler: function () {
          axios
            .delete("inventory/remove/" + items[0].inventory_id)
            .then(() => {
              alert("Успешно удалено", "success");
              navigate("/inventory");
            })
            .catch(() => {
              alert("Ошибка удаления", "danger");
            });
        },
      },
      {
        text: "Нет",
        handler: function () {
          alert("", "default", 1);
        },
      },
    ]);
  };

  // Метод для отправки скриншота
  const screenShot = async () => {
    const canvas = await html2canvas(toScreenShot.current, {
      useCORS: true,
    }).then((canv) => {
      const image = canv.toDataURL("image/png", 1.0);
      axios.post("inventory/editInventoryThumb/" + items[0].inventory_id, {
        thumb: image,
      });
    });
  };

  // Новая строка
  const addLine = () => {
    const copy = cloneDeep(items);
    copy.push({
      name: "",
      uchet: 0,
      fact: 0,
      spread: 0,
      total_uchet: 0,
      total_fact: 0,
      datas: [
        {
          spread: "",
          amount_fact: "",
          address_fact: "",
          amount_uchet: "",
          address_uchet: "",
        },
      ],
      inventory_id: copy[0].inventory_id,
      map_id: copy[0].map_id,
    });
    setItems(copy);
    setTimeout(() => {
      scrollCustomSelect(copy.length - 1);
    }, 200);
  };

  // Добавить колонку
  const addDataLine = (index) => {
    const copy = cloneDeep(items);
    copy[index].datas.push({
      address_uchet: "",
      amount_uchet: "",
      address_fact: "",
      amount_fact: "",
      spread: "",
    });
    setItems(copy);
  };

  // Скролл вниз при раскрытии списка с растениями внизу страницы
  const scrollPlus = useRef(null);
  const scrollCustomSelect = (index) => {
    if (items.length - 1 - index < 4)
      scrollPlus.current.scrollIntoView({ behavior: "smooth" });
  };

  // Метод изменения наименования
  const changeName = (index, name, value) => {
    if (name === "datas" && items[index].name === "") return;

    const copy = cloneDeep(items);

    // Проверка дублирующей позиции
    let error = false;
    copy.map((cp) => {
      if (cp[name] === value && name !== "b_group") error = true;
    });

    if (error) {
      alert("Такой товар уже есть в списке!", "danger");
      copy.splice(index, 1);
    } else copy[index][name] = value;

    setItems(copy);
  };

  // Расчет колонки Всего отклон.
  const totalSpread = () => {
    const copy = cloneDeep(items);
    items.map((it, ind) => {
      copy[ind].total_spread =
        parseInt(it.total_fact) - parseInt(it.total_uchet);
    });
    setItems(copy);
    setActiveTotalSpread(false);
  };

  // Проведение инвентаризации
  const provide = () => {
    alert("Провести в Системе?", "default", 10000, [
      {
        text: "Да",
        handler: function () {
          setIsPreloaderActive(true);
          save(false).then((saved) => {
            if (!saved) return;

            axios
              .get("inventory/provide/" + items[0].inventory_id)
              .then((response) => {
                // Дефолтные значения для алерта
                let alertMessage = "Успешно проведено";
                let alertType = "success";
                let alertDuration = 6000;

                // Проверка сообщений в ответе
                if (response.data.message.trim() !== "") {
                  alertMessage += "\r\n" + response.data.message;
                  alertType = "default";
                  alertDuration = 15000;
                }

                // Вывод алерта
                alert(alertMessage, alertType, alertDuration);

                // Флаг проведения
                const copy = cloneDeep(items);
                copy[0].provided = 1;
                setItems(copy);

                setIsPreloaderActive(false);
                navigate("/inventory").then(() =>
                  window.open(
                    "/inventory/edit/" + response.data.inventory_id,
                    "_blank"
                  )
                );
              })
              .catch((error) => {
                if (error.response.data.message)
                  alert(error.response.data.message, "danger", 10000);
                else alert("Ошибка проведения", "danger");
                setIsPreloaderActive(false);
              });
          });
        },
      },
      {
        text: "Нет",
        handler: function () {
          alert("", "default", 1);
        },
      },
    ]);
  };

  // Завершение инвентаризации через Бизнес.ру
  const setDone = () => {
    alert("Провести в базе?", "default", 10000, [
      {
        text: "Да",
        handler: function () {
          alert("", "default", 1);
          setIsPreloaderActive(true);

          axios
            .get("inventory/provide/" + items[0].inventory_id + "?done=1")
            .then((response) => {
              alert(
                "Успешно проведено." +
                  (response.data.message.length > 0
                    ? response.data.message
                    : ""),
                "success"
              );
              setIsPreloaderActive(false);
              navigate("/inventory");
            })
            .catch(() => {
              alert("Ошибка проведения", "danger");
              setIsPreloaderActive(false);
            });
        },
      },
      {
        text: "Нет",
        handler: function () {
          alert("", "default", 1);
        },
      },
    ]);
  };

  // Завершение инвентаризации вручную
  const setDoneManual = () => {
    alert("Переместить в архив?", "default", 10000, [
      {
        text: "Да",
        handler: function () {
          alert("", "default", 1);
          setIsPreloaderActive(true);

          axios
            .get(
              "inventory/provide/" + items[0].inventory_id + "?done=1&manual=1"
            )
            .then((response) => {
              alert(
                "Успешно архивировано." +
                  (response.data.message.length > 0
                    ? response.data.message
                    : ""),
                "success"
              );
              setIsPreloaderActive(false);
              navigate("/inventory");
            })
            .catch(() => {
              alert("Ошибка архивации", "danger");
              setIsPreloaderActive(false);
            });
        },
      },
      {
        text: "Нет",
        handler: function () {
          alert("", "default", 1);
        },
      },
    ]);
  };

  const handleProvideСorrection = (writeInHistory = true) => {
    setIsPreloaderActive(true);
    axios
      .get(
        "inventory/provideCorrection/" +
          items[0].inventory_id +
          "?writeInHistory=" +
          +writeInHistory
      )
      .then((response) => {
        alert(
          "Успешно архивировано." +
            (response.data.message.length > 0 ? response.data.message : ""),
          "success"
        );
        setIsPreloaderActive(false);
        navigate("/inventory");
      })
      .catch(() => {
        alert("Ошибка архивации", "danger");
        setIsPreloaderActive(false);
      });
  };

  // Удаление строки
  const deleteLine = (index) => {
    alert("Удалить строку?", "danger", 10000, [
      {
        text: "Да",
        handler: function () {
          const copy = cloneDeep(items);
          copy[index.index].datas.splice(index.i, 1);
          if (!copy[index.index].datas.length) copy.splice(index.index, 1);
          setItems(copy);
          alert("Строка удалена", "success");
        },
      },
      {
        text: "Нет",
        handler: function () {
          alert("", "default", 1);
        },
      },
    ]);
  };

  // Запись бригадира
  const setEmployee = (name, value) => {
    const copy = cloneDeep(items);
    copy[0].employee = value;
    setItems(copy);
  };

  // Функция сортировки растений
  const sortPlants = (name) => {
    setSortDir(!sortDir);

    const pl = cloneDeep(items);

    pl.sort((a, b) => {
      if (a[name] === b[name]) return 0;
      else if (!sortDir === true) return a[name] > b[name] ? 1 : -1;
      else return a[name] < b[name] ? 1 : -1;
    });

    setItems(pl);
    setSorted(name);
  };

  // Добавление файла
  const attachFile = (e) => {
    // Сборка FormData
    const formData = new FormData();
    Object.values(e.target.files).map((file, index) => {
      formData.append("file_" + index, file);
    });

    axios
      .post("inventory/setCommentFile/" + items[0].inventory_id, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        alert(
          response.data.files.length > 1 ? "Файлы загружены" : "Файл загружен",
          "success"
        );
        const fl = [...files, response.data.files];
        setFiles(fl[0]);
      })
      .catch(() => {
        alert("Ошибка добавления файла", "danger");
      });
  };

  // Удаление файла
  const removeFile = (url) => {
    alert("Удалить файл?", "danger", 10000, [
      {
        text: "Да",
        handler: function () {
          axios
            .post("inventory/removeCommentFile", { filename: url })
            .then(() => {
              alert("Файл удален", "success");
              const copy = cloneDeep(files);
              copy.map((cp, ind) => {
                if (cp.url === url) copy.splice(ind, 1);
              });
              setFiles(copy);
            });
        },
      },
      {
        text: "Нет",
        handler: function () {
          alert("", "default", 1);
        },
      },
    ]);
  };

  // Фильтрация наименований в зависимости от группы
  const filterNames = (groupId = 0) => {
    if (groupId === 0) return plantsNames;

    const filtered = plantsNames.filter((name) => {
      return name.b_group_id === groupId;
    });
    return filtered.sort((a, b) => {
      if (a.name === b.name) return 0;
      else return a.name > b.name ? 1 : -1;
    });
  };

  // Фильтрация артикулов в зависимости от группы
  const filterArticles = (groupId = 0) => {
    if (groupId === 0) return plantsArticles;

    return plantsArticles.filter((article) => {
      return article.b_group_id === groupId;
    });
  };

  useEffect(() => {
    dispatch(fetchInventory(id));
    dispatch(fetchGroups());
    dispatch(fetchGoods());
  }, []);

  useEffect(() => {
    if (inventory.data.length > 0) {
      dispatch(fetchAddresses());
      dispatch(fetchFields());

      setItems(inventory.data);
      setComment(inventory.data[0].comment);
      setFiles(inventory.data[0].files);
    }
  }, [inventory.data]);

  return (
    <>
      <title>Редактирование инвентаризации</title>

      <Preloader isActive={isPreloaderActive} />

      <div className={cl.floatPanel + " " + cl.controlsPanel}>
        {items.length > 0 &&
          items[0].provided === 1 &&
          items[0].is_copy > 0 &&
          getUser().accessLevel > 1 && (
            <span
              className="roundIcon dangerIcon material-icons"
              onClick={() => {
                alert("Отменить инвентаризацию?", "danger", 10000, [
                  {
                    text: "Да",
                    handler: function () {
                      alert("", "default", 6000, [], true);
                      axios
                        .get(
                          "inventory/provide/" +
                            items[0].inventory_id +
                            "?deny=1"
                        )
                        .then(() => {
                          alert("Успешно отменено", "success");
                          navigate("/inventory");
                        })
                        .catch(() => {
                          alert("Ошибка отмены", "danger");
                        });
                    },
                  },
                  {
                    text: "Нет",
                    handler: function () {
                      alert("", "default", 1);
                    },
                  },
                ]);
              }}
              title="Отменить инвентаризацию"
            >
              undo
            </span>
          )}

        {items.length > 0 &&
          items[0].provided === 0 &&
          getUser().accessLevel > 1 && (
            <span
              className="roundIcon dangerIcon material-icons"
              onClick={remove}
              title="Удалить инвентаризацию"
            >
              delete_sweep
            </span>
          )}

        {items.length > 0 &&
          items[0].provided === 0 &&
          getUser().accessLevel > 1 && (
            <span
              className="roundIcon material-icons"
              onClick={() =>
                alert("Сохранить инвентаризацию?", "default", 10000, [
                  {
                    text: "Да",
                    handler: function () {
                      save();
                    },
                  },
                  {
                    text: "Нет",
                    handler: function () {
                      alert("", "default", 1);
                    },
                  },
                ])
              }
              title="Сохранить"
            >
              save
            </span>
          )}

        <span
          className="roundIcon material-icons"
          title="Скачать"
          onClick={download}
        >
          download
        </span>

        {items.length > 0 &&
          items[0].provided === 0 &&
          getUser().accessLevel > 1 && (
            <span
              className="roundIcon material-icons"
              title="Провести Списание и Оприходование в Системе"
              onClick={provide}
            >
              checklist
            </span>
          )}

        {items.length > 0 &&
          items[0].provided === 1 &&
          items[0].is_copy > 0 &&
          getUser().accessLevel > 1 && (
            <>
              <span
                className="roundIcon material-icons"
                title="Переместить в архив"
                onClick={setDoneManual}
              >
                archive
              </span>
              <span
                className="roundIcon material-icons"
                title="Записать коррекцию"
                onClick={() =>
                  alert("Записать коррекцию в историю?", "default", 10000, [
                    {
                      text: "Да",
                      handler: function () {
                        handleProvideСorrection();
                      },
                    },
                    {
                      text: "Нет",
                      handler: function () {
                        handleProvideСorrection(false);
                      },
                    },
                  ])
                }
              >
                inventory
              </span>
              {items.length <= 300 && (
                <span
                  className="roundIcon material-icons"
                  title="Провести Списание и Оприходование в БизнесРу"
                  onClick={setDone}
                >
                  done
                </span>
              )}
            </>
          )}

        <span
          className="roundIcon material-icons"
          onClick={() => setModalIsActive(true)}
          title="Примечание"
        >
          maps_ugc
        </span>
        <span
          className="roundIcon material-icons"
          onClick={() => window.open("/faq/inventory", "_blank")}
          title="Инструкция"
        >
          quiz
        </span>
        <span
          className="roundIcon material-icons"
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          title="Фильтр"
        >
          filter_alt
        </span>
        <span
          className="roundIcon material-icons"
          title="Назад"
          onClick={() => navigate("/inventory")}
        >
          arrow_back
        </span>
      </div>

      <div className="stickyContainer" ref={toScreenShot}>
        <table
          className={tableClasses.join(" ")}
          onClick={() => setIsFilterOpen(false)}
        >
          <thead className={cl.top0 + " theadBordered thead-dark"}>
            <tr>
              <th colSpan="7">
                Инвентаризация по складу: {items.length > 0 && items[0].storage}
              </th>
              <th colSpan="5">
                № {items.length > 0 && items[0].document_number} от{" "}
                {items.length > 0 && items[0].date}
              </th>
              <th colSpan="3">
                {items.length > 0 && items[0].author && (
                  <>
                    Автор:&nbsp;
                    {items[0].author}
                    <br />
                  </>
                )}
                Бригадир:&nbsp;
                <Input
                  type="text"
                  name="employee"
                  value={items.length > 0 && items[0].employee}
                  setValue={setEmployee}
                  placeholder="Бригадир"
                  title={items.length > 0 && items[0].employee}
                />
              </th>
              {items.length > 0 && items[0].provided === 0 && (
                <th className={cl.borderNone} rowSpan="4" colSpan="2" />
              )}
            </tr>
            <tr>
              <th rowSpan="3">№</th>
              <th
                rowSpan="3"
                className={sorted === "name" ? "sort sorted" : "sort"}
                onClick={() => sortPlants("name")}
              >
                <span>Наименование</span>
              </th>
              <th
                rowSpan="3"
                className={sorted === "article" ? "sort sorted" : "sort"}
                onClick={() => sortPlants("article")}
              >
                <span>Артикул</span>
              </th>
              <th rowSpan="3">Группа</th>
              <th colSpan="3">По базе</th>
              <th colSpan="8" className={colors.b5}>
                По системе учета
              </th>
            </tr>
            <tr>
              <th rowSpan="2">Учет</th>
              <th rowSpan="2">Факт</th>
              <th rowSpan="2">Отклон.</th>
              <th rowSpan="2" className={colors.b7}>
                Всего учет {totals.uchet}
              </th>
              <th colSpan="2" className={colors.b7}>
                в том числе
              </th>
              <th rowSpan="2" className={colors.b5}>
                Всего факт {totals.fact}
              </th>
              <th colSpan="3" className={colors.b5}>
                в том числе
              </th>
              <th rowSpan="2" className={colors.b5}>
                Всего отклон. <br />
                <br />
                {items.length > 0 &&
                  items[0].provided === 0 &&
                  activeTotalSpread && (
                    <Button type="accent" onClick={totalSpread}>
                      Рассчитать
                    </Button>
                  )}
              </th>
            </tr>
            <tr>
              <th className={colors.b7}>Адрес</th>
              <th className={colors.b7}>Кол-во</th>
              <th className={colors.b5}>Адрес</th>
              <th className={colors.b5}>Кол-во</th>
              <th className={colors.b5}>
                {items.length > 0 && items[0].is_copy > 0
                  ? "Было отклон."
                  : "Отклон."}
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((it, index) => (
              <Fragment key={index.toString()}>
                {it.datas.map((dt, i) => (
                  <tr key={i.toString()}>
                    {i === 0 && (
                      <>
                        <td className={cl.center} rowSpan={it.datas.length}>
                          {index + 1}
                        </td>
                        <td
                          rowSpan={it.datas.length}
                          onClick={() => scrollCustomSelect(index)}
                        >
                          {it.name === "" ? (
                            <CustomSelect
                              options={filterNames(it.b_group_id)}
                              currentValue={it.name}
                              currentIndex={index}
                              name="name"
                              data={items}
                              groups={groups.data}
                              changeHandler={changeName}
                              placeholder="Наименование"
                            />
                          ) : (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate("/history?name=" + it.name)
                              }
                            >
                              {it.name}
                            </span>
                          )}
                        </td>
                        <td
                          rowSpan={it.datas.length}
                          onClick={() => scrollCustomSelect(index)}
                        >
                          {it.name === "" ? (
                            <CustomSelect
                              options={filterArticles(it.b_group_id)}
                              currentValue={it.article}
                              groups={groups.data}
                              currentIndex={index}
                              name="article"
                              data={items}
                              changeHandler={changeName}
                              placeholder="Артикул"
                            />
                          ) : (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate("/history?name=" + it.name)
                              }
                            >
                              {it.article}
                            </span>
                          )}
                        </td>
                        <td
                          rowSpan={it.datas.length}
                          onClick={() => scrollCustomSelect(index)}
                        >
                          {it.name === "" ? (
                            <CustomSelect
                              options={groups.data}
                              currentValue={it.b_group}
                              currentIndex={index}
                              name="b_group"
                              data={items}
                              changeHandler={changeName}
                              placeholder="Группа"
                            />
                          ) : (
                            it.b_group
                          )}
                        </td>
                        <td className={cl.right} rowSpan={it.datas.length}>
                          {new Intl.NumberFormat("ru-RU").format(it.uchet)}
                        </td>
                        <td className={cl.right} rowSpan={it.datas.length}>
                          {new Intl.NumberFormat("ru-RU").format(it.fact)}
                        </td>
                        <td className={cl.right} rowSpan={it.datas.length}>
                          {new Intl.NumberFormat("ru-RU").format(it.spread)}
                        </td>
                        <td
                          className={cl.right + " " + colors.b7}
                          rowSpan={it.datas.length}
                        >
                          {new Intl.NumberFormat("ru-RU").format(
                            it.total_uchet
                          )}
                        </td>
                      </>
                    )}

                    <td
                      onClick={() =>
                        onChange(
                          { index: index, i: i },
                          "address_fact",
                          dt.address_uchet
                        )
                      }
                      className={colors.b7 + " " + cl.address}
                    >
                      {dt.address_uchet}
                    </td>
                    <td
                      onClick={() =>
                        onChange(
                          { index: index, i: i },
                          "amount_fact",
                          dt.amount_uchet
                        )
                      }
                      className={colors.b7 + " " + cl.right}
                    >
                      {new Intl.NumberFormat("ru-RU").format(dt.amount_uchet)}
                    </td>

                    {i === 0 && (
                      <td
                        className={cl.right + " " + colors.b5}
                        rowSpan={it.datas.length}
                      >
                        {new Intl.NumberFormat("ru-RU").format(it.total_fact)}
                      </td>
                    )}

                    <td className={colors.b5 + " " + cl.address}>
                      {items[0].provided === 1 ? (
                        dt.address_fact
                      ) : (
                        <CustomSelect
                          options={addresses.data}
                          currentValue={dt.address_fact}
                          currentIndex={{ index: index, i: i }}
                          name="address_fact"
                          changeHandler={onChange}
                          placeholder="Адрес"
                        />
                      )}
                    </td>
                    <td className={colors.b5 + " " + cl.right}>
                      {items[0].provided === 1 ? (
                        new Intl.NumberFormat("ru-RU").format(dt.amount_fact)
                      ) : (
                        <Input
                          type="text"
                          name="amount_fact"
                          placeholder="Факт"
                          value={dt.amount_fact}
                          setValue={onChange}
                          data={items}
                          currentIndex={{ index: index, i: i }}
                        />
                      )}
                    </td>
                    <td className={colors.b5 + " " + cl.right}>
                      {new Intl.NumberFormat("ru-RU").format(dt.spread)}
                    </td>

                    {i === 0 && (
                      <td
                        className={cl.right + " " + colors.b5}
                        rowSpan={it.datas.length}
                      >
                        {!it.total_spread
                          ? 0
                          : new Intl.NumberFormat("ru-RU").format(
                              it.total_spread
                            )}
                      </td>
                    )}

                    {items[0].provided === 0 && (
                      <td className="iconed">
                        <span
                          className="roundIcon material-icons"
                          onClick={() => deleteLine({ index: index, i: i })}
                          title="Удалить строку"
                        >
                          delete_outline
                        </span>
                      </td>
                    )}

                    {i === 0 && items[0].provided === 0 && (
                      <td
                        className={"iconed " + cl.bottom}
                        rowSpan={it.datas.length}
                      >
                        <span
                          className="roundIcon material-icons"
                          onClick={() => addDataLine(index)}
                          title="Добавить строку"
                        >
                          add
                        </span>
                      </td>
                    )}
                  </tr>
                ))}
              </Fragment>
            ))}

            {items.length > 0 && items[0].provided === 0 && (
              <tr className="adding">
                <td className="iconed">
                  <span
                    className="roundIcon material-icons"
                    onClick={addLine}
                    title="Добавить наименование"
                  >
                    add
                  </span>
                </td>
              </tr>
            )}
            <tr ref={scrollPlus} style={{ height: "1px" }} />
          </tbody>
        </table>
        <FilterInventory
          isOpen={isFilterOpen}
          data={items}
          setData={setItems}
          currentSort={sorted}
          setIsFilterActive={setIsFilterActive}
          sortFn={sortPlants}
          onlyOne="name"
          dataBackup={dataBackup}
          setDataBackup={setDataBackup}
          fieldList={fields.data}
        />
      </div>

      <Modal isActive={modalIsActive} setIsActive={setModalIsActive}>
        {getUser().accessLevel > 1 ? (
          <>
            <textarea
              placeholder="Примечание"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
            />
            <input type="file" name="files[]" onChange={attachFile} multiple />
          </>
        ) : (
          comment
        )}
        {files.map((file) => (
          <div className="fileIconGroup">
            {getUser().accessLevel > 1 && (
              <span
                className="material-icons remove"
                title="Удалить"
                onClick={() => removeFile(file.url)}
              >
                cancel
              </span>
            )}
            <span
              className="material-icons file"
              onClick={() =>
                window.open(
                  process.env.REACT_APP_SERVER_URL + file.url,
                  "_blank"
                )
              }
            >
              description
            </span>
            <span
              className="name"
              onClick={() =>
                window.open(
                  process.env.REACT_APP_SERVER_URL + file.url,
                  "_blank"
                )
              }
            >
              {file.name}
            </span>
          </div>
        ))}
      </Modal>
    </>
  );
};

// export async function getServerSideProps({params}){
// 	const apiToken = "Bearer ~ybqF7}S9G9a5@8}aIex";

// 	// Запрос инвентаризации
// 	const itemsResponse = await axios.get("inventory/getItems/" + params.id, {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const Items = itemsResponse.data.result.sort((a, b) => {
// 		if(a.name === b.name) return 0;
// 		else return a.name > b.name ? 1 : -1;
// 	});

// 	// Запрос всех адресов
// 	const addressListResponse = await axios.get("map/getAllAddresses", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const addressListRaw = addressListResponse.data.result;
// 	const addressList = addressListRaw.filter(adr => adr.storage === Items[0].storage);

// 	// Запрос инвентаризации
// 	const groupsResponse = await axios.get("businessRu/getGroups", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const groups = groupsResponse.data.result;

// 	// Запрос всех полей
// 	const fieldListResponse = await axios.get("map/getAllFields", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const fieldListRaw = fieldListResponse.data.result;
// 	const fieldList = fieldListRaw.filter(field => field.storage === Items[0].storage);

// 	return {props: {Items, addressList, groups, fieldList}}
// }

export default InventoryEdit;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { removeToken } from "helpers/storage";
import { user } from "constants/endpoints";

const fetchInfo = createAsyncThunk("user/info", async () => {
  const response = await fetcher(user.INFO);
  return response.json();
});

const postLogin = createAsyncThunk("login", async (data) => {
  const response = await fetcher(user.LOGIN, {
    method: "POST",
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }

  const result = await response.json();
  return result;
});

const initialState = {
  login: {
    token: null,
    status: loadingStatus.IDLE,
    error: null,
  },
  info: {},
};

const userSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logOut(state) {
      removeToken();
      state.login.token = initialState.login.token;
      state.login.status = initialState.login.status;
      state.login.error = initialState.login.error;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(postLogin.pending, (state, _) => {
        state.login.status = loadingStatus.LOADING;
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.login.status = loadingStatus.SUCCEEDED;
        state.login.token = action.payload;
      })
      .addCase(postLogin.rejected, (state, action) => {
        state.login.status = loadingStatus.FAILED;
        state.login.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(fetchInfo.fulfilled, (state, action) => { state.info =  action.payload.result; })
  }
});

const { logOut } = userSlice.actions

export default userSlice.reducer;

export {
  postLogin,
  fetchInfo,
  logOut,
};

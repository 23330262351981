import cl from "styles/components/cabinet/UsersTable.module.scss";

import {useContext, useEffect, useState} from "react";
import Indicator from "components/Indicator";
import Button from "components/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { AppContext } from "providers/AppContextProvider";
import { fetchRealizations } from "redux/slices/docSlice";
import { getUser } from "helpers/storage";
import { useDispatch, useSelector } from "react-redux";

const Index = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { realizations } = useSelector((state) => state.doc);

  const { setCrumbs } = useContext(AppContext);	
	// Состояние со списком отгрузок
	// const [realizations, setRealizations] = useState([]);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "Отгрузки", url: ""}
		]);
	}, []);

  useEffect(() => {
    dispatch(fetchRealizations());
  }, []);
	
	return (
		<>
			
				<title>Отгрузки</title>
						
			<div style={{padding: "16px"}}>
				&nbsp;
				<Indicator type="danger"/> - Отгрузка создана &nbsp;
				<Indicator type="success"/> - Проведено в Системе
			</div>
			
			<table className={cl.usersTable}>
				<thead>
					<tr>
						<th colSpan="2">
							{getUser().accessLevel > 1 &&
								<Button type="accent" onClick={() => navigate("/realizations/new")}>
									Новая отгрузка
								</Button>
							}
						</th>
						<th/>
						<th>
							<Button type="white" onClick={() => navigate("/archive/realizations")}>
								Архив
							</Button>
						</th>
						<th colSpan="2">
							<Button type="white" onClick={() => window.open("/faq/realization")}>
								Инструкция
							</Button>
						</th>
					</tr>
					<tr>
						<th>Системный №</th>
						<th>Заказ</th>
						<th>Клиент</th>
						<th>Дата в Системе</th>
						<th/>
						<th/>
					</tr>
				</thead>
				<tbody>
					{realizations.data.map((realization, index) =>
						<tr key={index.toString()}>
							<td>
								<span>{realization.document_number}</span>
							</td>
							<td>
								<span>{realization.number}</span>
							</td>
							<td>
								<span>{realization.customer}</span>
							</td>
							<td>
								<span>{realization.document_date}</span>
							</td>
							<td>
								<span className={cl.icon + " material-icons"} title="Посмотреть"
										onClick={() => navigate("/realizations/edit/" + realization.id)}>
									visibility
								</span>
							</td>
							<td>
								<Indicator type={realization.provided === 1 ? "success" : "danger"}/>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</>
	)
}

export default Index;
import cl from "styles/pages/[fieldId].module.scss";
import colors from "styles/pages/[boardId].module.scss";
import Input from "components/input/InputComponent";
import CustomSelect from "../CustomSelect";
import React, {Fragment, useEffect, useState, useRef} from "react";
import axios from "axios";
import Modal from "../Modal";
import { useNavigate } from "react-router-dom";

const FactoryForm = ({alert, setMenuCondition, factory, groups, addressList, Provided, userLevel}) => {
	const navigate = useNavigate();
	const router = {
		pathname: window.location.pathname,
		push: (p) => navigate(p),
		query: { type: (new URLSearchParams(window.location.search)).get("type") },
	};
	
	// Объект пустой строки
	const emptyLine = {
		b_group: "", // Название группы в Бизнес.ру
		b_group_id: 0, // Идентификатор группы в Бизнес.ру
		article: "", // Артикул
		article_after: "", // Артикул
		name: "", // Наименование
		name_after: "", // Наименование
		good_id: 0, // id товара в Бизнес.ру
		good_id_after: 0, // id в Бизнес.ру для новой продукции
		amounts: [{amount: "", address: ""}], // Остатки на складах
		totalAmount: 0, // Общий остаток на складах
		totalAmount_before: 0, // Всего до
		totalAmount_after: 0, // Всего после
		userPositions: [{amount: "", address: "", amount_after: "", address_after: ""}], // Пользовательский ввод позиции
		isError: false // Флаг ошибки в строке
	};
	const emptyMaterial = {
		name: "",
		article: "",
		b_group: "",
		b_group_id: 0,
		good_id: "",
		totalAmount: 0,
		amounts: [{amount: "", address: ""}],
		userPositions: [{counter: "", amount: ""}],
		measure: "",
		isMaterial: true
	};
	
	// Объект для текущей даты
	const date = new Date();
	
	// Параметры документа
	const [documentNumber, setDocumentNumber] = useState(factory.document_number ? factory.document_number : "");
	const [documentDate, setDocumentDate] = useState(factory.document_date ? factory.document_date : "");
	const [factoryDate, setFactoryDate] = useState(factory.factory_date ? factory.factory_date : "");
	const [employee, setFormEmployee] = useState(factory.employee ? factory.employee : "");
	const [comment, setComment] = useState(factory.comment ? factory.comment : "");
	const [provided, setProvided] = useState(Provided);
	
	// Массив строк таблицы с начальной пустой строкой
	const [lines, setLines] = useState(factory.form ? JSON.parse(JSON.stringify(factory.form)) : [JSON.parse(JSON.stringify(emptyLine))]);
	const [materials, setMaterials] = useState(factory.materials ? JSON.parse(JSON.stringify(factory.materials)) : [JSON.parse(JSON.stringify(emptyMaterial))]);
	const [totalBefore, setTotalBefore] = useState(0);
	const [totalAfter, setTotalAfter] = useState(0);
	
	// Асинхронные данные из Бизнес.ру
	const [plantsNames, setPlantsNames] = useState([]);
	const [plantsArticles, setPlantsArticles] = useState([]);
	
	// Подзаголовок
	const [subName, setSubName] = useState(factory.sub_name);
	
	// Поле сортировки и направление
	const [sorted, setSorted] = useState("name");
	const [sortDir, setSortDir] = useState(true);
	const [sortedMaterials, setSortedMaterials] = useState("name");
	const [sortDirMaterials, setSortDirMaterials] = useState(true);
	
	// Модальное окно
	const [modalIsActive, setModalIsActive] = useState(false);
	
	// Файлы в примечании
	const [files, setFiles] = useState(factory.files ? factory.files : []);
	
	// Классы таблицы
	const tableClasses = [cl.mainTable, cl.top0, "table", "table-responsive"];
	
	// Интервал автосохранения
	const autoSaveInterval = 300000;
	
	useEffect(() => {
		// Установка даты документа
		if(!factory.document_date)
			setDocumentDate(String(date.getDate()).padStart(2, "0") + "." + String(date.getMonth() + 1).padStart(2, "0") + "." + date.getFullYear() + " " + String(date.getHours()).padStart(2, "0") + ":" + String(date.getMinutes()).padStart(2, "0"));
		
		// Запрос наименований растений
		axios.get("businessRu/getGoods").then(response => {
			setPlantsNames(response.data.result.goods.sort((a, b) => {
				if(a.name === b.name) return 0;
				else return a.name > b.name ? 1 : -1;
			}));
			setPlantsArticles(response.data.result.articles);
		});
	}, []);
	
	useEffect(() => {
		// Подсчет общего кол-ва
		let totalB = 0;
		let totalA = 0;
		lines.map(ln => {
			if(!isNaN(parseInt(ln.totalAmount_before))) totalB += parseInt(ln.totalAmount_before);
			if(!isNaN(parseInt(ln.totalAmount_after))) totalA += parseInt(ln.totalAmount_after);
		});
		setTotalBefore(totalB);
		setTotalAfter(totalA);
		
		// Изменение количества материалов
		const mCopy = Object.assign([], materials);
		mCopy.map((cp, index) => {
			cp.userPositions.map((up, ind) => {
				if(!isNaN(parseInt(up.counter))) mCopy[index].userPositions[ind].amount = totalA * up.counter;
				else mCopy[index].userPositions[ind].amount = 0;
			});
		});
		setMaterials(mCopy);
		
		// Вывод уведомления при переходе по ссылке, если есть несохраненные данные
		if((lines.length === 1 && lines[0].name === "") || JSON.stringify(lines) === JSON.stringify(factory.form)){
			setMenuCondition({});
			return;
		}
		
		// Установка условия с обязательной очисткой после перехода по ссылке
		setMenuCondition({
			text: "Есть несохраненные данные. Сохранить?",
			type: "danger",
			duration: 10000,
			buttons: [
				{
					text: "Да",
					handler: function(href){
						const saved = save(true);
						if(saved) router.push(href);
					}
				},
				{
					text: "Нет",
					handler: function(href){
						alert("", "danger", 1);
						router.push(href).then(() => {
							setMenuCondition({});
						});
					}
				}
			]
		});
		
		// Автосохранение
		for(let i = 1; i < 1000; i++) clearInterval(i);
		setInterval(() => {
			if(provided === 0) save(false, false);
		}, autoSaveInterval);
	}, [lines]);
	
	// Скролл вниз при раскрытии списка с растениями внизу страницы
	/*const scrollPlus = useRef(null);
	const scrollCustomSelect = (index) => {
		if(lines.length - 1 - index < 6) scrollPlus.current.scrollIntoView({behavior: "smooth"});
	}*/
	const scrollPlusMaterial = useRef(null);
	const scrollCustomSelectMaterial = (index) => {
		if(materials.length - 1 - index < 6) scrollPlusMaterial.current.scrollIntoView({behavior: "smooth"});
	}
	
	// Изменение наименований
	const onChangeName = (index, name, value) => {
		if((name === "amounts" || name === "totalAmount") && lines[index].name === "") return;
		
		const copy = Object.assign([], lines);
		
		// Проверка дублирующей позиции
		let error = false;
		if(name === "name" || name === "article"){
			copy.map(cp => {
				if(cp[name] === value) error = true;
			});
		}
		
		if(error){
			alert("Такой товар уже есть в списке!", "danger");
			copy.splice(index, 1);
		} else copy[index][name] = value;
		
		// Запись контейнера
		if(name === "name_after"){
			const container = value.split(", ");
			copy.map((cp, ind) => {
				copy[ind].container = container[1];
			});
		}
		
		// Особый флаг для услуг
		if(name === "b_group" && value === "Услуги"){
			copy[index].isUsl = true;
			copy[index].userPositions[0].amount = 1;
		}
		
		setLines(copy);
	}
	
	// Изменение позиций
	const onChangePos = (index, name, value) => {
		const copy = Object.assign([], lines);
		
		// Изменение столбцов "Всего"
		if(name === "amount"){
			if(!isNaN(parseInt(copy[index.index].userPositions[index.ind][name])))
				copy[index.index].totalAmount_before -= parseInt(copy[index.index].userPositions[index.ind][name]);
			if(!isNaN(parseInt(value))) copy[index.index].totalAmount_before += parseInt(value);
		}
		if(name === "amount_after"){
			if(isNaN(parseInt(copy[index.index].totalAmount_after))) copy[index.index].totalAmount_after = 0;
			if(!isNaN(parseInt(copy[index.index].userPositions[index.ind][name])))
				copy[index.index].totalAmount_after -= parseInt(copy[index.index].userPositions[index.ind][name]);
			if(!isNaN(parseInt(value))) copy[index.index].totalAmount_after += parseInt(value);
		}
		
		copy[index.index].userPositions[index.ind][name] = value;
		setLines(copy);
	}
	
	// Добавление строки
	const addLine = (index = -1, firstDeleted = false) => {
		const copy = !firstDeleted ? Object.assign([], lines) : [];
		
		// Копирование или вставка новой строки
		if(index > -1) copy.push(JSON.parse(JSON.stringify(copy[index])));
		else{
			copy.push(JSON.parse(JSON.stringify(emptyLine)));
			copy[0].userPositions.map((pos, ind) => {
				if(ind > 0)
					copy[copy.length - 1].userPositions.push(JSON.parse(JSON.stringify(emptyLine.userPositions[0])));
			});
			if(copy[0].container) copy[copy.length - 1].container = copy[0].container;
		}
		
		setLines(copy);
	}
	
	// Удаление строки
	const deleteLine = (index) => {
		const copy = Object.assign([], lines);
		
		// Удаление позиций для ввода
		copy[index.index].userPositions.splice(index.ind, 1);
		copy[index.index].amounts.splice(index.ind, 1);
		
		// Если позиций для ввода не осталось, удаление строки
		if(copy[index.index].amounts.length === 0) copy.splice(index.index, 1);
		
		// Если удалена последняя строка, автоматическое добавление новой
		if(copy.length === 0) addLine(-1, true);
		else setLines(copy);
	}
	
	// Добавление колонки с пользовательским вводом
	const addColumn = (index) => {
		const copy = Object.assign([], lines);
		copy[index].userPositions.push(JSON.parse(JSON.stringify(emptyLine.userPositions[0])));
		copy[index].amounts.push(JSON.parse(JSON.stringify(emptyLine.amounts[0])));
		setLines(copy);
	}
	
	// Изменение наименований расходников
	const onChangeNameMaterial = (index, name, value) => {
		if((name === "amounts" || name === "totalAmount") && materials[index].name === "") return;
		
		const copy = Object.assign([], materials);
		
		// Проверка дублирующей позиции
		let error = false;
		if(name === "name" || name === "article"){
			copy.map(cp => {
				if(cp[name] === value) error = true;
			});
		}
		
		if(error){
			alert("Такой материал уже есть в списке!", "danger");
			copy.splice(index, 1);
		} else copy[index][name] = value;
		
		setMaterials(copy);
	}
	
	// Изменение позиций расходников
	const onChangePosMaterial = (index, name, value) => {
		const copy = Object.assign([], materials);
		copy[index.index].userPositions[index.ind][name] = value;
		copy[index.index].userPositions[index.ind].amount = value * totalAfter;
		setMaterials(copy);
	}
	
	// Добавление строки
	const addLineMaterial = (index = -1) => {
		const copy = Object.assign([], materials);
		
		// Копирование или вставка новой строки
		if(index > -1) copy.push(JSON.parse(JSON.stringify(copy[index])));
		else{
			copy.push(JSON.parse(JSON.stringify(emptyLine)));
			copy[0].userPositions.map((pos, ind) => {
				if(ind > 0)
					copy[copy.length - 1].userPositions.push(JSON.parse(JSON.stringify(emptyLine.userPositions[0])));
			});
		}
		
		setMaterials(copy);
		scrollCustomSelectMaterial(copy.length - 1);
	}
	
	// Удаление строки
	const deleteLineMaterial = (index) => {
		const copy = Object.assign([], materials);
		copy[index.index].userPositions.splice(index.ind, 1);
		copy[index.index].amounts.splice(index.ind, 1);
		if(copy[index.index].amounts.length === 0) copy.splice(index.index, 1);
		setMaterials(copy);
	}
	
	// Сохранение данных
	const save = async(fromMenu = false, allowValidation = true, toCopy = false) => {
		alert("", "default", 6000, [], true);
		
		// Проверка бригадира
		if(employee === ""){
			alert("Укажите ответственного бригадира", "danger");
			return false;
		}
		
		// Валидация формы
		let formError = false;
		let errorNotified = false;
		const copy = Object.assign([], lines);
		allowValidation && copy.map((c, i) => {
			let error = false;
			
			// Проверка пустых полей
			if(c.name === "" || c.name_after === ""){
				formError = true;
				error = true;
				errorNotified = true;
				alert("Не указано наименование", "danger");
			}
			
			// Проверка даты документа
			if(documentDate === "" || documentDate === "00.00.0000 00:00"){
				formError = true;
				errorNotified = true;
				alert("Введите дату документа", "danger");
			}
			
			// Проверка даты производства
			if(factoryDate === "" || factoryDate === "00.00.0000 00:00"){
				formError = true;
				errorNotified = true;
				alert("Введите дату производства", "danger");
			}
			
			c.b_group !== "Услуги" && c.userPositions.map((up, ind) => {
				// Проверка корректного расхода
				if(parseInt(up.amount) > parseInt(c.amounts[ind].amount)){
					formError = true;
					error = true;
					errorNotified = true;
					alert("Расход больше остатка", "danger");
					return false;
				}
				
				// Проверка адресов прихода
				if((up.address_after === "" || !up.address_after) && parseInt(up.amount_after) > 0){
					formError = true;
					error = true;
					errorNotified = true;
					alert("Введите адрес для новой продукции", "danger");
					return false;
				}
			});
			
			if(error){
				lines[i].isError = true;
				setTimeout(() => {
					lines[i].isError = false;
				}, 5000);
			}
		});
		
		// Валидация материалов
		const mCopy = Object.assign([], materials);
		allowValidation && mCopy.map((cp, index) => {
			let error = false;
			
			// Проверка пустых полей
			if(cp.name === ""){
				formError = true;
				error = true;
				errorNotified = true;
				alert("Не указано наименование", "danger");
			}
			
			// Проверка корректного расхода
			cp.userPositions.map((up, ind) => {
				if(parseInt(up.amount) > parseInt(cp.amounts[ind].amount)){
					formError = true;
					error = true;
					errorNotified = true;
					alert("Расход больше остатка", "danger");
					return false;
				}
			});
			
			if(error){
				materials[index].isError = true;
				setTimeout(() => {
					materials[index].isError = false;
				}, 5000);
			}
		});
		
		if(formError){
			if(!errorNotified) alert("Некорректно заполнены поля таблиц", "danger");
			return false;
		}
		
		// Отправка данных
		const url = factory.id > 0 && !toCopy ? "factory/save/" + factory.id : "factory/save";
		return axios.post(url, {
			subName: subName,
			form: copy,
			materials: mCopy,
			document: {
				number: toCopy ? documentNumber + " (копия)" : documentNumber,
				date: documentDate,
				factoryDate: factoryDate
			},
			employee: employee,
			comment: comment
		}).then(response => {
			if(!response.data.message) alert("Успешно сохранено", "success");
			else alert(response.data.message, "default");
			setMenuCondition({});
			if((!factory.id && !fromMenu) || toCopy) router.push("/factory/" + response.data.id);
			
			return true;
		}).catch(() => {
			alert("Ошибка сохранения", "danger");
			return false;
		});
	}
	
	// Фильтрация наименований в зависимости от группы
	const filterNames = (groupId, container = "") => {
		if(groupId === 0 && container === "") return plantsNames;
		
		// Если указан контейнер, дополнительная фильтрация по нему
		const filtered = plantsNames.filter(name => {
			if(container !== ""){
				if(groupId !== 0) return name.b_group_id === groupId && name.name.includes(", " + container);
				else return name.name.includes(", " + container);
			} else return name.b_group_id === groupId;
		});
		return filtered.sort((a, b) => {
			if(a.name === b.name) return 0;
			else return a.name > b.name ? 1 : -1;
		});
	}
	
	// Фильтрация артикулов в зависимости от группы
	const filterArticles = (groupId, container) => {
		if(groupId === 0 && container === "") return plantsArticles;
		
		return plantsArticles.filter(article => {
			if(container !== ""){
				if(groupId !== 0) return article.b_group_id === groupId && article.good.includes(", " + container);
				else return article.good.includes(", " + container);
			} else return article.b_group_id === groupId;
		});
	}
	
	// Запись ответственного бригадира
	const setEmployee = (name, value) => {
		const copy = Object.assign([], lines);
		copy[0].employee = value;
		setLines(copy);
		setFormEmployee(value);
	}
	
	// Проведение в Системе
	const provide = () => {
		alert("Провести в Системе?", "default", 10000, [
			{
				text: "Да",
				handler: function(){
					save().then(saved => {
						if(!saved) return false;
						
						axios.get("factory/provide/" + factory.id).then(response => {
							// Дефолтные значения для алерта
							let alertMessage = "Производство проведено";
							let alertType = "success";
							let alertDuration = 6000;
							
							// Проверка сообщений в ответе
							response.data.form.map(form => {
								if(form.message.trim() !== ""){
									alertMessage += "\r\n" + response.data.form.message;
									alertType = "default";
									alertDuration = 15000;
								}
							});
							
							// Вывод алерта
							alert(alertMessage, alertType, alertDuration);
							
							setProvided(1);
						}).catch(() => {
							alert("Ошибка проведения", "danger");
						});
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Проведение в Бизнес.ру
	const done = () => {
		alert("Провести в Базе?", "default", 10000, [
			{
				text: "Да",
				handler: function(){
					save().then(saved => {
						if(!saved) return false;
						
						axios.get("factory/provide/" + factory.id + "?done=1").then(() => {
							alert("Производство проведено", "success");
							router.push("/factory");
						}).catch(() => {
							alert("Ошибка проведения", "danger");
						});
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Удаление документа
	const deleteFactory = () => {
		alert("Удалить производство?", "danger", 10000, [
			{
				text: "Да",
				handler: function(){
					axios.delete("factory/remove/" + factory.id).then(() => {
						alert("Производство удалено", "success");
						router.push("/factory");
					}).catch(() => {
						alert("Ошибка удаления", "danger");
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Отмена проведения в Системе
	const deny = () => {
		alert("Отменить проведение?", "danger", 10000, [
			{
				text: "Да",
				handler: function(){
					axios.get("factory/provide/" + factory.id + "?deny=1").then(() => {
						alert("Производство отменено", "success");
						setProvided(0);
					}).catch(() => {
						alert("Ошибка отмены", "danger");
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Копирование документа
	const copyFactory = () => {
		alert("Скопировать документ производства?", "default", 10000, [
			{
				text: "Да",
				handler: function(){
					save(false, false, true);
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Скачивание файла с таблицами производства
	const print = () => {
		save(false, false).then(saved => {
			if(!saved) return;
			
			axios.post("factory/print", {
				factory: lines,
				materials: materials,
				document_number: documentNumber,
				document_date: documentDate,
				factory_id: factory.id,
				totalBefore: totalBefore,
				totalAfter: totalAfter
			}).then(response => {
				router.push("/FILES/" + response.data.filename).then(() => {
					router.push("/factory/" + factory.id);
				});
			}).catch(() => {
				alert("Ошибка скачивания", "danger");
			});
		});
	}
	
	// Добавление файла
	const attachFile = (e) => {
		// Сборка FormData
		const formData = new FormData();
		Object.values(e.target.files).map((file, index) => {
			formData.append("file_" + index, file);
		});
		
		axios.post("factory/setFile/" + factory.id, formData, {
			headers: {
				"Content-type": "multipart/form-data",
			}
		}).then(response => {
			alert(response.data.files.length > 1 ? "Файлы загружены" : "Файл загружен", "success");
			const fl = [...files, response.data.files];
			setFiles(fl[0]);
		}).catch(() => {
			alert("Ошибка добавления файла", "danger");
		});
	}
	
	// Удаление файла
	const removeFile = (url) => {
		alert("Удалить файл?", "danger", 10000, [
			{
				text: "Да",
				handler: function(){
					axios.post("factory/removeFile", {filename: url}).then(() => {
						alert("Файл удален", "success");
						const copy = Object.assign([], files);
						copy.map((cp, ind) => {
							if(cp.url === url) copy.splice(ind, 1);
						});
						setFiles(copy);
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Функция сортировки растений
	const sortPlants = (name) => {
		setSortDir(!sortDir);
		
		const pl = Object.assign([], lines);
		
		pl.sort((a, b) => {
			if(a[name] === b[name]) return 0;
			else if(!sortDir === true) return a[name] > b[name] ? 1 : -1;
			else return a[name] < b[name] ? 1 : -1;
		});
		
		setLines(pl);
		setSorted(name);
	}
	
	// Функция сортировки материалов
	const sortMaterials = (name) => {
		setSortDirMaterials(!sortDirMaterials);
		
		const pl = Object.assign([], materials);
		
		pl.sort((a, b) => {
			if(a[name] === b[name]) return 0;
			else if(!sortDirMaterials === true) return a[name] > b[name] ? 1 : -1;
			else return a[name] < b[name] ? 1 : -1;
		});
		
		setMaterials(pl);
		setSortedMaterials(name);
	}
	
	return (
		<>
			<div className={cl.floatPanel + " " + cl.controlsPanel}>
				{provided === 1 && factory.done === 0 && userLevel() > 1 &&
					<>
						<span className="roundIcon dangerIcon material-icons" onClick={deny} title="Отменить проведение">
							undo
						</span>
						<span className="roundIcon material-icons" onClick={done} title="Провести в Базе">
							done
						</span>
					</>
				}
				
				{factory.id > 0 && provided === 0 && userLevel() > 1 &&
					<>
						<span className="roundIcon dangerIcon material-icons" onClick={deleteFactory} title="Удалить">
							delete_sweep
						</span>
						<span className="roundIcon material-icons" onClick={provide} title="Провести в Системе">
							checklist
						</span>
					</>
				}
				
				{factory.id > 0 && userLevel() > 1 &&
					<span className="roundIcon material-icons" onClick={copyFactory} title="Копировать">
						copy
					</span>
				}
				
				{provided === 0 && userLevel() > 1 &&
					<span className="roundIcon material-icons"
							onClick={() => alert("Сохранить?", "default", 10000, [
								{
									text: "Да",
									handler: function(){
										save(false, false);
									}
								},
								{
									text: "Нет",
									handler: function(){
										alert("", "default", 1);
									}
								}
							])} title="Сохранить">save</span>
				}
				
				{factory.id > 0 &&
					<>
						<span className="roundIcon material-icons" onClick={print} title="Скачать">
							download
						</span>
						<span className="roundIcon material-icons" onClick={() => setModalIsActive(true)} title="Примечание">
							maps_ugc
						</span>
					</>
				}
				
				<span className="roundIcon material-icons" onClick={() => window.open("/faq/factory", "_blank")}
						title="Инструкция">
					quiz
				</span>
			</div>
			
			<div className="stickyContainer">
				<table className={tableClasses.join(" ") + " " + cl.overflowWide} style={{zIndex: "30"}}>
					<thead className={cl.top0 + " theadBordered thead-dark"}>
						<tr>
							<th colSpan="2" className={cl.borderNone}>
								{documentNumber !== "" && <>Производство № {documentNumber}<br/></>}
								{provided === 0
									?
									<textarea name="subName" placeholder="Примечание" value={subName} style={{marginTop: "8px"}}
												 onChange={(e) => setSubName(e.target.value)}/>
									: <b>{subName}</b>
								}
							</th>
							<th className={cl.borderNone + " " + cl.dateInput}>
								{documentNumber !== "" && <>от&nbsp;</>}
								{provided === 0
									? <Input type="text" name="documentDate" autocomplete="off" mask="99.99.9999 99:99"
												placeholder="Дата" value={documentDate}
												setValue={(name, value) => setDocumentDate(value)}/>
									: documentDate
								}
							</th>
							<th>
								{factory.author &&
									<>
										Автор:&nbsp;
										{factory.author}
										<br/>
									</>
								}
								Бригадир:&nbsp;
								<Input type="text" name="employee" value={employee} setValue={setEmployee}
										 placeholder="Бригадир"
										 title={employee}/>
							</th>
							<th colSpan="3" className={colors.b5}>В наличии</th>
							<th colSpan="3" className={colors.b7}>Используемый материал</th>
							<th colSpan="5" className={cl.dateInput + " " + colors.b5}>
								Продукция план&nbsp;
								{provided === 0
									? <Input type="text" name="documentDate" autocomplete="off" mask="99.99.9999 99:99"
												placeholder="Дата произв." value={factoryDate}
												setValue={(name, value) => setFactoryDate(value)}/>
									: factoryDate
								}
							</th>
							{provided === 0 && <th className={cl.borderNone} rowSpan="3" colSpan="2"/>}
						</tr>
						<tr>
							<th rowSpan="2">№</th>
							<th rowSpan="2" className={sorted === "name" ? "sort sorted" : "sort"}
								 onClick={() => sortPlants("name")}>
								<span>Название</span>
							</th>
							<th rowSpan="2" className={sorted === "article" ? "sort sorted" : "sort"}
								 onClick={() => sortPlants("article")}>
								<span>Артикул</span>
							</th>
							<th rowSpan="2">
								Группа
							</th>
							<th rowSpan="2" className={colors.b5}>Всего:</th>
							<th colSpan="2" className={colors.b5}>В том числе:</th>
							<th rowSpan="2" className={colors.b7}>Всего:</th>
							<th colSpan="2" className={colors.b7}>В том числе:</th>
							<th rowSpan="2" className={colors.b5}>Название</th>
							<th rowSpan="2" className={colors.b5}>Артикул</th>
							<th rowSpan="2" className={colors.b5}>Всего:</th>
							<th colSpan="2" className={colors.b5}>В том числе:</th>
						</tr>
						<tr>
							<th className={colors.b5}>Кол-во</th>
							<th className={colors.b5}>Адрес</th>
							<th className={colors.b7}>Кол-во</th>
							<th className={colors.b7}>Адрес</th>
							<th className={colors.b5}>Кол-во</th>
							<th className={colors.b5}>Адрес</th>
						</tr>
					</thead>
					<tbody>
						{lines.map((line, index) =>
							<Fragment key={index.toString()}>
								{line.amounts.map((ln, i) =>
									<tr key={i.toString()} className={line.isError ? cl.error : ""}>
										{i === 0 &&
											<>
												<td rowSpan={line.amounts.length}>{index + 1}</td>
												<td rowSpan={line.amounts.length} className={cl.name}>
													{provided === 0 && line.name === ""
														?
														<CustomSelect options={filterNames(line.b_group_id)} currentValue={line.name}
																		  currentIndex={index} name="name" data={lines} groups={groups}
																		  changeHandler={onChangeName} placeholder="Наименование"/>
														: line.name
													}
												</td>
												<td rowSpan={line.amounts.length}>
													{provided === 0 && line.article === ""
														? <CustomSelect options={filterArticles(line.b_group_id)}
																			 currentValue={line.article} currentIndex={index}
																			 name="article"
																			 data={lines} groups={groups} changeHandler={onChangeName}
																			 placeholder="Артикул"/>
														: line.article
													}
												</td>
												<td rowSpan={line.amounts.length}>
													{provided === 0 && line.b_group === ""
														? <CustomSelect options={groups} currentValue={line.b_group}
																			 currentIndex={index}
																			 name="b_group" changeHandler={onChangeName}
																			 placeholder="Группа"/>
														: line.b_group
													}
												</td>
												<td rowSpan={line.amounts.length}
													 className={cl.right + " " + colors.b5 + " " + cl.date}>
													{line.isUsl !== true && new Intl.NumberFormat("ru-RU").format(line.totalAmount)}
												</td>
											</>
										}
										
										<td className={cl.right + " " + colors.b5 + " " + cl.date}>
											{line.isUsl !== true && new Intl.NumberFormat("ru-RU").format(ln.amount)}
										</td>
										<td className={colors.b5 + " " + cl.date}>{line.isUsl !== true && ln.address}</td>
										
										{i === 0 &&
											<td rowSpan={line.amounts.length}
												 className={cl.right + " " + colors.b7 + " " + cl.date}>
												{line.isUsl !== true ? new Intl.NumberFormat("ru-RU").format(line.totalAmount_before) : 1}
											</td>
										}
										
										<td className={cl.right + " " + colors.b7 + " " + cl.date}>
											{provided === 0 && line.isUsl !== true
												? <Input type="number" name="amount" placeholder="Кол-во"
															value={line.userPositions[i].amount}
															setValue={onChangePos} currentIndex={{index: index, ind: i}}/>
												: new Intl.NumberFormat("ru-RU").format(line.userPositions[i].amount)
											}
										</td>
										<td className={colors.b7 + " " + cl.date}>
											{line.isUsl !== true && line.userPositions[i].address}
										</td>
										
										{i === 0 &&
											<>
												<td rowSpan={line.amounts.length} className={cl.name + " " + colors.b5}>
													{provided === 0 && (line.name_after === "" || !line.name_after) && line.isUsl !== true
														?
														<CustomSelect options={filterNames(0, lines[0].container)}
																		  currentValue={line.name_after} currentIndex={index}
																		  name="name_after" data={lines} changeHandler={onChangeName}
																		  placeholder="Наименование"/>
														: line.name_after
													}
												</td>
												<td rowSpan={line.amounts.length} className={colors.b5}>
													{provided === 0 && (line.article_after === "" || !line.article_after) && line.isUsl !== true
														? <CustomSelect options={filterArticles(0, lines[0].container)}
																			 currentValue={line.article_after} currentIndex={index}
																			 name="article_after" data={lines} changeHandler={onChangeName}
																			 placeholder="Артикул"/>
														: line.article_after
													}
												</td>
												<td rowSpan={line.amounts.length}
													 className={cl.right + " " + colors.b5 + " " + cl.date}>
													{!isNaN(parseInt(line.totalAmount_after)) && line.isUsl !== true
														? new Intl.NumberFormat("ru-RU").format(line.totalAmount_after)
														: line.totalAmount_after
													}
												</td>
											</>
										}
										
										<td className={cl.right + " " + colors.b5 + " " + cl.date}>
											{provided === 0 && line.isUsl !== true
												? <Input type="number" name="amount_after" placeholder="Кол-во"
															value={line.userPositions[i].amount_after}
															setValue={onChangePos} currentIndex={{index: index, ind: i}}/>
												: !line.userPositions[i].amount_after || line.userPositions[i].amount_after === 0 ? 0 : new Intl.NumberFormat("ru-RU").format(line.userPositions[i].amount_after)
											}
										</td>
										<td className={colors.b5 + " " + cl.date}>
											{provided === 0 && line.isUsl !== true
												? <CustomSelect options={addressList}
																	 currentValue={line.userPositions[i].address_after}
																	 currentIndex={{index: index, ind: i}} name="address_after"
																	 changeHandler={onChangePos} placeholder="Адрес"/>
												: line.userPositions[i].address_after
											}
										</td>
										
										{provided === 0 &&
											<td className="iconed">
									<span className="roundIcon material-icons"
											onClick={() => deleteLine({index: index, ind: i})}
											title="Удалить строку">delete_outline</span>
											</td>
										}
										
										{i === 0 && provided === 0 &&
											<td rowSpan={line.amounts.length} className="iconed">
									<span className="roundIcon material-icons" onClick={() => addColumn(index)}
											title="Добавить колонку">add</span>
											</td>
										}
									</tr>
								)}
							</Fragment>
						)}
						
						<tr>
							<td className={cl.borderNone} colSpan="7"/>
							<td className={cl.right + " " + colors.b7 + " " + colors.bold + " " + cl.date}>
								{new Intl.NumberFormat("ru-RU").format(totalBefore)}
							</td>
							<td className={cl.borderNone} colSpan="2"/>
							<td className={cl.right + " " + colors.b5 + " " + colors.bold} colSpan="2">
								Итого новой продукции, шт.:
							</td>
							<td className={cl.right + " " + colors.b5 + " " + colors.bold + " " + cl.date}>
								{!isNaN(parseInt(totalAfter))
									? new Intl.NumberFormat("ru-RU").format(totalAfter)
									: 0
								}
							</td>
						</tr>
						
						{provided === 0 &&
							<tr className="adding">
								<td className="iconed">
									<span className="roundIcon material-icons" onClick={addLine}
											title="Добавить строку">add</span>
								</td>
							</tr>
						}
						<tr style={{height: "50px"}}/>
					</tbody>
				</table>
				
				<table className={tableClasses.join(" ") + " " + cl.overflow} style={{zIndex: "20"}}>
					<thead className={cl.top0 + " theadBordered thead-dark"}>
						<tr>
							<th rowSpan="2">№</th>
							<th rowSpan="2" className={sortedMaterials === "name" ? "sort sorted" : "sort"}
								 onClick={() => sortMaterials("name")}>
								<span>Название</span>
							</th>
							<th rowSpan="2" className={sortedMaterials === "article" ? "sort sorted" : "sort"}
								 onClick={() => sortMaterials("article")}>
								<span>Артикул</span>
							</th>
							<th rowSpan="2">Группа</th>
							<th rowSpan="2" className={colors.b5}>Всего:</th>
							<th colSpan="2" className={colors.b5}>В том числе:</th>
							<th colSpan="3" className={colors.b7}>Расходные материалы (план)</th>
							<th rowSpan="2" colSpan="2" className={cl.borderNone}/>
						</tr>
						<tr>
							<th className={colors.b5}>Кол-во</th>
							<th className={colors.b5}>Адрес</th>
							<th className={colors.b7}>На ед.</th>
							<th className={colors.b7}>Всего</th>
							<th className={colors.b7}>Ед. изм.</th>
						</tr>
					</thead>
					<tbody>
						{materials.map((material, index) =>
							<Fragment key={index.toString()}>
								{material.amounts.map((mt, ind) =>
									<tr key={ind.toString()} className={material.isError ? cl.error : ""}>
										{ind === 0 &&
											<>
												<td rowSpan={material.amounts.length}>{index + 1}</td>
												<td rowSpan={material.amounts.length} style={{width: "23%"}}>
													{provided === 0 && material.name === ""
														? <CustomSelect options={filterNames(material.b_group_id)}
																			 currentValue={material.name} currentIndex={index} name="name"
																			 data={materials} groups={groups}
																			 changeHandler={onChangeNameMaterial}
																			 placeholder="Наименование материала"/>
														: material.name
													}
												</td>
												<td rowSpan={material.amounts.length} style={{width: "10%"}}>
													{provided === 0 && material.article === ""
														? <CustomSelect options={filterArticles(material.b_group_id)}
																			 currentValue={material.article} currentIndex={index}
																			 name="article" data={materials} groups={groups}
																			 changeHandler={onChangeNameMaterial} placeholder="Артикул"/>
														: material.article
													}
												</td>
												<td rowSpan={material.amounts.length}>
													{provided === 0 && material.b_group === ""
														? <CustomSelect options={groups} currentValue={material.b_group}
																			 currentIndex={index} name="b_group"
																			 changeHandler={onChangeNameMaterial} placeholder="Группа"/>
														: material.b_group
													}
												</td>
												<td rowSpan={material.amounts.length} className={cl.right + " " + colors.b5}
													 style={{width: "9%"}}>
													{new Intl.NumberFormat("ru-RU").format(material.totalAmount)}
												</td>
											</>
										}
										
										<td className={cl.right + " " + colors.b5} style={{width: "8%"}}>
											{new Intl.NumberFormat("ru-RU").format(mt.amount)}
										</td>
										<td className={colors.b5} style={{width: "8%"}}>{mt.address}</td>
										
										<td className={cl.right + " " + colors.b7} style={{width: "8%"}}>
											{provided === 0
												? <Input type="number" name="counter" placeholder="На ед."
															value={material.userPositions[ind].counter} setValue={onChangePosMaterial}
															currentIndex={{index: index, ind: ind}}/>
												: new Intl.NumberFormat("ru-RU").format(material.userPositions[ind].counter)
											}
										</td>
										<td className={cl.right + " " + colors.b7}>
											{new Intl.NumberFormat("ru-RU").format(material.userPositions[ind].amount)}
										</td>
										
										{ind === 0 &&
											<td rowSpan={material.amounts.length} className={colors.b7} style={{width: "7%"}}>
												{material.measure}
											</td>
										}
										
										{provided === 0 &&
											<td className="iconed">
									<span className="roundIcon material-icons"
											onClick={() => deleteLineMaterial({index: index, ind: ind})}
											title="Удалить строку">delete_outline</span>
											</td>
										}
									</tr>
								)}
							</Fragment>
						)}
						
						{provided === 0 &&
							<tr className="adding">
								<td className="iconed">
									<span className="roundIcon material-icons" onClick={addLineMaterial}
											title="Добавить строку">add</span>
								</td>
							</tr>
						}
						
						<tr style={{height: "150px"}}/>
						<tr ref={scrollPlusMaterial}/>
					</tbody>
				</table>
			</div>
			
			<Modal isActive={modalIsActive} setIsActive={setModalIsActive}>
				{provided === 0
					? <>
									<textarea placeholder="Примечание" onChange={(e) => setComment(e.target.value)}>
								{comment}
									</textarea>
						<input type="file" name="files[]" onChange={attachFile} multiple/>
					</>
					: <p>{comment}</p>
				}
				{files.map(file =>
					<div className="fileIconGroup">
						{provided === 0 &&
							<span className="material-icons remove" title="Удалить" onClick={() => removeFile(file.url)}>
									cancel
									</span>
						}
						<span className="material-icons file"
								onClick={() => file.ext === "pdf" ? window.open(file.url, "_blank") : router.push(file.url).then(() => router.push(router.asPath))}>
									description
									</span>
						<span className="name"
								onClick={() => file.ext === "pdf" ? window.open(file.url, "_blank") : router.push(file.url).then(() => router.push(router.asPath))}>
								{file.name}
									</span>
					</div>
				)}
			</Modal>
		</>
	);
};

export default FactoryForm;
import {Polygon, Tooltip} from "react-leaflet";
import {useEffect, useState} from "react";
import PolygonEditableMarker from "./PolygonEditableMarker";
import { useNavigate } from "react-router-dom";

const PolygonEditable = ({children, coords, changeHandler, index, isEditing, isMapDrawing, mapId, fieldId}) => {
	const navigate = useNavigate();
	
	// Состояние отрисовки
	const [isDrawing, setIsDrawing] = useState(false);
	
	// Вершины полигона
	const [positions, setPositions] = useState(coords);
	
	// Добавление дополнительных точек для изменения фигуры
	useEffect(() => {
		const extPositions = [];
		coords.map((pos, index) => {
			extPositions.push(pos);
			if(index !== coords.length - 1)
				extPositions.push([(pos[0] + coords[index + 1][0]) / 2, (pos[1] + coords[index + 1][1]) / 2, true]);
			else
				extPositions.push([(pos[0] + coords[0][0]) / 2, (pos[1] + coords[0][1]) / 2, true]);
		});
		
		setPositions(extPositions);
	}, [coords]);
	
	// Изменение основного массива после отрисовки
	useEffect(() => {
		if(!isDrawing){
			// Фильтрация по основным точкам
			const pos = positions.filter(p => typeof p[2] === "undefined");
			changeHandler(index, "coords", pos);
		}
	}, [isDrawing]);
	
	// Метод изменения вершин полигона
	const changePositions = (index, value) => {
		const copy = Object.assign([], positions);
		copy[index] = value;
		setPositions(copy);
	}
	
	// Перенаправление на страницу поля
	const fieldMove = () => {
		if(!isEditing && !isMapDrawing)
			navigate("/map/" + mapId + "/fields/" + fieldId);
	}
	
	return (
		<>
			{isEditing && positions.map((position, index) =>
				<PolygonEditableMarker key={index.toString()} position={position} changePosition={changePositions}
											  coordIndex={index} setIsDrawing={setIsDrawing}/>
			)}
			<Polygon pathOptions={{color: "black"}} positions={positions} eventHandlers={{click: fieldMove}}>
				<Tooltip direction="center" opacity={1} permanent>
					{children}
				</Tooltip>
			</Polygon>
		</>
	);
};

export default PolygonEditable;
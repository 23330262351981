import cl from "styles/pages/[fieldId].module.scss";
import {useContext, useEffect, useState} from "react";
import Button from "../../components/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "providers/AppContextProvider";
import { fetchCurrentNames } from "redux/slices/historySlice";
import { loadingStatus } from "helpers/fetcher";

const Select = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const { currentNames } = useSelector((state) => state.history);
  
	const { setCrumbs } = useContext(AppContext);	
	// Классы таблиц
	const tableClasses = [cl.mainTable, cl.top0, "table", "table-responsive"];
	
	// Данные с сервера
	const [names, setNames] = useState([]);
	
	// Строка поиска
	const [search, setSearch] = useState("");
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "История", url: ""}
		]);
	}, []);

	// Обработка поискового запроса
	useEffect(() => {
		if(search.trim() === ""){
			setNames(currentNames.data);
			return;
		}
		
		setNames(currentNames.data.filter(name => {
			return name.toLowerCase().includes(search.toLowerCase());
		}));
	}, [search]);

	useEffect(() => {
		if (currentNames.status === loadingStatus.SUCCEEDED) {
			setNames(currentNames.data);
		}
	}, [currentNames]);

	useEffect(() => { dispatch(fetchCurrentNames()); }, []);
	
	return (
		<>
			<title>Текущие движения товаров</title>
			
			<table className={tableClasses.join(" ")}>
				<thead className={cl.top0 + " theadBordered thead-dark"}>
					<tr>
						<th>
							Название
						</th>
						<th>
							<input type="text" value={search} placeholder="Поиск по наименованию" style={{maxWidth: "100%"}}
									 onChange={(e) => setSearch(e.target.value)}/>
						</th>
						<th style={{width: "300px"}}>
							<Button type="default" onClick={() => window.open("/faq/history", "_blank")}>Инструкция</Button>
						</th>
					</tr>
				</thead>
				<tbody>
					{names.length > 0 && names.map(name =>
						<tr key={name}>
							<td colSpan="3" className={cl.router}
								 onClick={() => navigate("/history?name=" + name)}>
								{name}
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</>
	);
};

export default Select;
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import cl from "styles/components/Filter.module.scss";
import CustomSelect from "./CustomSelect";

const Filter = ({
  isOpen = false,
  data,
  setData,
  sortFn = false,
  storages = [],
  addressList = [],
  fieldList = [],
  groups = [],
  plants = [],
  articles = [],
  multiple = false,
}) => {
  const [searchParams] = useSearchParams();

  const classes = [cl.filterContainer];
  if (isOpen) classes.push(cl.open);

  // Копия данных для очистки фильтра
  const [dataBackup, setDataBackup] = useState([]);

  // Состояние значений фильтра
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    // Бэкап данных
    let copy = Object.assign([], data);
    setDataBackup(copy);

    // Фильтрация по складу, если он есть в урле
    if (searchParams.storage)
      setFilter(
        0,
        "storage",
        multiple ? [searchParams.storage] : searchParams.storage
      );
  }, []);

  // Запись отфильтрованных значений в основной массив
  useEffect(() => {
    // Если нет фильтров, возврат старых значений
    if (!filters.length && dataBackup.length > 0) {
      setData(dataBackup);
      return;
    }

    // Если нет начальных данных, полезут ошибки
    if (!dataBackup.length) return;

    // Метод фильтрации
    const filterMethod = (obj, value, dt) => {
      // Фильтрация строковых значений
      if (obj === "b_group" || obj === "name" || obj === "article") {
        if (dt[obj] === null) return "";
        dt[obj] = dt[obj].toString();
        return dt[obj].toLowerCase() === value.toLowerCase();
      } else if (obj === "address" || obj === "field" || obj === "storage") {
        // Поиск совпадений по площадке, адресу, складу
        return (
          dt.positions.filter((pos) => {
            if (obj === "storage")
              return pos.storage.toLowerCase().includes(value.toLowerCase());
            else if (obj === "field")
              return pos.address
                .toLowerCase()
                .includes(value.toLowerCase() + ".");
            else return pos.address.toLowerCase().includes(value.toLowerCase());
          }).length > 0
        );
      } else {
        // Фильтрация даты
        if (obj === "date_after")
          return (
            new Date(dt.date).valueOf() >= new Date(dateFormat(value)).valueOf()
          );
        if (obj === "date_before")
          return (
            new Date(dt.date).valueOf() <= new Date(dateFormat(value)).valueOf()
          );
      }
    };

    // Фильтрация
    let newData = Object.assign([], JSON.parse(JSON.stringify(dataBackup)));
    let newDataPositions = Object.assign(
      [],
      JSON.parse(JSON.stringify(dataBackup))
    );
    filters.map((item) => {
      // Выполнение сортировки
      if (sortFn !== false && (item.obj === "row" || item.obj === "name"))
        sortFn(item.obj);

      // Выполнение фильтрации
      const filtered = newData.filter((dt, index) => {
        if (multiple && item.obj !== "name") {
          let toReturn = false;
          item.value.map((val, ind) => {
            // Проверка необходимости возврата всей строки
            if (filterMethod(item.obj, val, dt)) toReturn = true;

            // Основная фильтрация по адресу, площадке, складу
            if (
              (item.obj === "address" ||
                item.obj === "field" ||
                item.obj === "storage") &&
              toReturn
            ) {
              if (!dt.positions.filtered) dt.positions = [];

              dt.positions = dt.positions.concat(
                newDataPositions[index].positions.filter((pos) => {
                  if (item.obj === "storage")
                    return pos.storage
                      .toLowerCase()
                      .includes(val.toLowerCase());
                  else if (item.obj === "field")
                    return pos.address
                      .toLowerCase()
                      .includes(val.toLowerCase() + ".");
                  else return pos.address.toLowerCase() === val.toLowerCase();
                })
              );
              dt.positions.filtered = true;
            }
          });
          return toReturn;
        } else return filterMethod(item.obj, item.value, dt);
      });

      // Запись отфильтрованных данных
      setData(filtered);
      newData = filtered;
    });
  }, [filters]);

  // Установка значений фильтра
  const setFilter = (index = 0, obj, value) => {
    if (!value.toString().length || value === "__.__.____ __:__") {
      // Удаление строки с фильтром, если значение не задано
      const delFilter = filters.filter((item) => item.obj !== obj);
      setFilters(delFilter);
    } else {
      // При каждом изменении значения фильтра - удаление строки и добавление нового значения
      const addFilter = filters.filter((item) => item.obj !== obj);
      addFilter.push({ obj: obj, value: value });
      setFilters(addFilter);
    }
  };

  // Сброс фильтра
  const eraseFilter = () => {
    setFilters([]);
  };

  // Форматирование даты
  const dateFormat = (dt) => {
    if (dt == null || dt === "") return "";

    dt = dt.split(" ");
    dt[0] = dt[0].split(".");
    if (dt[0][0] === "0000") return "";
    else return dt[0][2] + "-" + dt[0][1] + "-" + dt[0][0] + " " + dt[1];
  };

  return (
    <div className={classes.join(" ")}>
      <span className={cl.heading}>Фильтры</span>
      <span
        className={cl.filterOff + " material-icons"}
        onClick={eraseFilter}
        title="Сбросить фильтр"
      >
        filter_alt_off
      </span>

      {storages.length > 0 && (
        <CustomSelect
          options={storages}
          name="storage"
          changeHandler={setFilter}
          currentIndex="0"
          placeholder="Склад"
          multiple={multiple}
          className="select-search fixed-dropdown"
          currentValue={
            filters.find((fl) => fl.obj === "storage") !== undefined
              ? filters.find((fl) => fl.obj === "storage").value
              : ""
          }
        />
      )}
      {fieldList.length > 0 && (
        <CustomSelect
          options={fieldList}
          name="field"
          changeHandler={setFilter}
          currentIndex="0"
          placeholder="Площадка / поле"
          multiple={multiple}
          className="select-search fixed-dropdown"
          currentValue={
            filters.find((fl) => fl.obj === "field") !== undefined
              ? filters.find((fl) => fl.obj === "field").value
              : ""
          }
        />
      )}
      {addressList.length > 0 && (
        <CustomSelect
          options={addressList}
          name="address"
          changeHandler={setFilter}
          currentIndex="0"
          placeholder="Адрес целиком"
          multiple={multiple}
          className="select-search fixed-dropdown"
          currentValue={
            filters.find((fl) => fl.obj === "address") !== undefined
              ? filters.find((fl) => fl.obj === "address").value
              : ""
          }
        />
      )}
      {groups.length > 0 && (
        <CustomSelect
          options={groups}
          name="b_group"
          changeHandler={setFilter}
          currentIndex="0"
          placeholder="Группа"
          multiple={multiple}
          className="select-search fixed-dropdown"
          currentValue={
            filters.find((fl) => fl.obj === "b_group") !== undefined
              ? filters.find((fl) => fl.obj === "b_group").value
              : ""
          }
        />
      )}
      {articles.length > 0 && (
        <CustomSelect
          options={articles}
          name="article"
          changeHandler={setFilter}
          currentIndex="0"
          placeholder="Артикул"
          multiple={multiple}
          className="select-search fixed-dropdown"
          currentValue={
            filters.find((fl) => fl.obj === "article") !== undefined
              ? filters.find((fl) => fl.obj === "article").value
              : ""
          }
        />
      )}
      {plants.length > 0 && (
        <CustomSelect
          options={plants}
          name="name"
          changeHandler={setFilter}
          currentIndex="0"
          placeholder="Наименование"
          className="select-search fixed-dropdown"
          currentValue={
            filters.find((fl) => fl.obj === "name") !== undefined
              ? filters.find((fl) => fl.obj === "name").value
              : ""
          }
        />
      )}
    </div>
  );
};

export default Filter;

import cl from "styles/components/InputPlantDate.module.scss";
import {useEffect, useState} from "react";

const InputDate = ({currentValue = "", currentIndex = -1, name, changeHandler}) => {
	const [date, setDate] = useState(currentValue);
	
	// Запуск переданной функции для изменения значения
	useEffect(() => {
		if(date !== currentValue) currentIndex > -1 ? changeHandler(currentIndex, name, date) : changeHandler(name, date);
	}, [date]);
	
	// Ловим очистку поля для ввода
	useEffect(() => {
		if(currentValue != null) if(currentValue.trim() === "") setDate("");
	}, [currentValue]);
	
	return (
		<input type="date" value={date} className={cl.plantDate}
				 onChange={(e) => setDate(e.currentTarget.value)}/>
	);
};

export default InputDate;
import cl from "styles/components/Preloader.module.scss";

const SpinPlaceholderComponent = ({ isActive = false, isSmall }) => {
  const classes = [cl.preloader];
  if (isActive) classes.push(cl.active);
  if (isSmall) classes.push(cl.small);

  return (
    <div className={classes.join(" ")}>
      <img
        src={`${process.env.REACT_APP_SERVER_URL}/img/preloader_arrow.svg`}
        className={"spinner " + cl.spin}
        alt="Первое Агентство"
      />
      <img
        src={`${process.env.REACT_APP_SERVER_URL}/img/agro_logo.svg`}
        className={cl.logo}
        alt="Первое Агентство"
      />
    </div>
  );
};

export default SpinPlaceholderComponent;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { doc } from "constants/endpoints";

const fetchCurrentNames = createAsyncThunk("history/getCurrentNames", async () => {
  const response = await fetcher("/history/getCurrentNames");
  return response.json();
});


const initialState = {
  currentNames: {
    status: loadingStatus.IDLE,
    data: [],
    error: null,
  },
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCurrentNames.pending, (state) => {
        state.currentNames.status = loadingStatus.LOADING;
      })
      .addCase(fetchCurrentNames.fulfilled, (state, action) => {
        state.currentNames.status = loadingStatus.SUCCEEDED;
        state.currentNames.data = action.payload.result;
      })
      .addCase(fetchCurrentNames.rejected, (state, action) => {
        state.currentNames.status = loadingStatus.FAILED;
        state.currentNames.error = `${action.error.name}: ${action.error.message}`;
      })
  }
});

export default historySlice.reducer;

export {
  fetchCurrentNames,
};

import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import MapHandlers from "./MapHandlers";
import PolygonEditable from "./PolygonEditable";
import MapPanel from "./MapPanel";
import AlertComponent from "components/AlertComponent";

const MapEdit = ({ map, allFields }) => {
  // Начальные состояния полей
  const [fields, setFields] = useState([]);

  // Флаг отрисовки
  const [isDrawing, setIsDrawing] = useState(false);

  // Работа с уведомлениями todok требуется рефакторинг всех алертов с применением динамического импорта
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState("");
  const [alertIsActive, setAlertIsActive] = useState(false);
  const [alertButtons, setAlertButtons] = useState([]);
  const alert = (alertText, alertType, hideDuration = 6000, buttons = []) => {
    // Вызов окна уведомления
    setAlertText(alertText);
    setAlertType(alertType);
    setAlertIsActive(true);
    setAlertButtons(buttons);

    // Скрытие уведомления
    setTimeout(() => {
      setAlertIsActive(false);

      setTimeout(() => {
        setAlertText("");
        setAlertType("");
      }, hideDuration);
    }, hideDuration);
  };

  // Изменение основного массива полей
  const changeHandler = (index, name, value) => {
    fields[index] = { ...fields[index], [name]: value };
    setFields([...fields]);
  };

  useEffect(() => {
    let _fields = map.data.fields ? [...map.data.fields] : [];
    if (map.data.fields?.length > 0) {
      _fields.sort((a, b) => (a.id > b.id ? 1 : -1))
    }
    setFields(_fields);
  }, [map.data]);

  console.log(map.data)

  return (
    <>
      <AlertComponent
        alertType={alertType}
        alertIsActive={alertIsActive}
        buttons={alertButtons}
      >
        {alertText}
      </AlertComponent>

      <MapPanel
        map={map.data}
        fields={fields}
        setFields={setFields}
        alert={alert}
        allowEdit={true}
      />

      <MapContainer
        center={[map.data.center_x, map.data.center_y]}
        zoom={map.data.zoom}
        scrollWheelZoom={true}
        style={{ cursor: "crosshair" }}
        attributionControl={false}
        doubleClickZoom={false}
        preferCanvas={true}
        zoomControl={false}
      >
        <MapHandlers
          fields={fields}
          setFields={setFields}
          Map={map.data}
          AllFields={allFields}
          isDrawing={isDrawing}
          setIsDrawing={setIsDrawing}
        />

        <TileLayer
          url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
        <ZoomControl position="topright" />

        {fields.map((field, index) => (
          <PolygonEditable
            key={field.name}
            coords={field.coords}
            changeHandler={changeHandler}
            index={index}
            isEditing={field.isEditing}
            isMapDrawing={isDrawing}
            mapId={map.data.id}
            fieldId={field.id}
          >
            {field.name}
          </PolygonEditable>
        ))}
      </MapContainer>
    </>
  );
};

export default MapEdit;

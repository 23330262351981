import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { inventory, maps } from "constants/endpoints";

const fetchInventories = createAsyncThunk("inventory/all", async () => {
  const response = await fetcher(inventory.ALL);
  return response.json();
});

const fetchInventory = createAsyncThunk("inventory/getItems", async (id) => {
  const response = await fetcher(`${inventory.ITEMS}/${id}`);
  return response.json();
});

// TODO: это дубль экшена fetchAddresses
// нужно переписать на запрос с параметром типа ?storage=storage
// и оставить унифицированный экшен
const fetchAddresses = createAsyncThunk("inventory/getAllAddresses", async () => {
  const response = await fetcher(maps.ADDRESSES);
  return response.json();
});

// TODO: это дубль экшена fetchMapFields
// нужно переписать на запрос с параметром типа ?storage=storage
// и оставить унифицированный экшен
const fetchFields = createAsyncThunk("inventory/getAllFields", async () => {
  const response = await fetcher(maps.FIELDS);
  return response.json();
});

// Отправка файла на сервер
const postFile = async (file) => {
  const formData = new FormData();
  formData.append("act", file);
  const response = await fetcher(inventory.POST_FILE, {
    method: "POST",
    body: formData,
  });
  return response;
}

const initialState = {
  inventories: {
    status: loadingStatus.IDLE,
    data: [],
    error: null,
  },
  inventory: {
    status: loadingStatus.IDLE,
    data: [],
  },
  addresses: {
    status: loadingStatus.IDLE,
    data: [],
  },
  fields: {
    status: loadingStatus.IDLE,
    data: [],
  },
};

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchInventories.pending, (state) => {
        state.inventories.status = loadingStatus.LOADING;
      })
      .addCase(fetchInventories.fulfilled, (state, action) => {
        state.inventories.status = loadingStatus.SUCCEEDED;
        state.inventories.data = action.payload.result;
      })
      .addCase(fetchInventories.rejected, (state, action) => {
        state.inventories.status = loadingStatus.FAILED;
        state.inventories.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(fetchInventory.pending, (state) => {
        state.inventory.status = loadingStatus.LOADING;
      })
      .addCase(fetchInventory.fulfilled, (state, action) => {
        state.inventory.status = loadingStatus.SUCCEEDED;
        state.inventory.data = action.payload.result;
        // TODO: весь подобный треш перенести в API
        state.inventory.data.sort((a, b) => {
          if(a.name === b.name) return 0;
          else return a.name > b.name ? 1 : -1;
        });
      })
  
      .addCase(fetchAddresses.pending, (state) => {
        state.addresses.status = loadingStatus.LOADING;
      })
      .addCase(fetchAddresses.fulfilled, (state, action) => {
        state.addresses.status = loadingStatus.SUCCEEDED;
        const filteredAddresses = action.payload.result.filter(adr => adr.storage === state.inventory.data[0].storage);
        state.addresses.data = filteredAddresses;
      })

      .addCase(fetchFields.pending, (state) => {
        state.addresses.status = loadingStatus.LOADING;
      })
      .addCase(fetchFields.fulfilled, (state, action) => {
        state.addresses.status = loadingStatus.SUCCEEDED;
        const fieldList = action.payload.result.filter(field => field.storage === state.inventory.data[0].storage);
        state.addresses.data = fieldList;

      })
  }
});

export default inventorySlice.reducer;

export {
  fetchInventories,
  fetchInventory,
  fetchAddresses,
  fetchFields,
  postFile,
};

import Button from "components/ButtonComponent";
import cl from "styles/components/Modal.module.scss";

const Modal = ({children, isActive, setIsActive}) => {
	const classes = [cl.modalContainer];
	if(isActive) classes.push(cl.active);
	
	return (
		<div className={classes.join(" ")}>
			<div className={cl.modalBg} onClick={() => setIsActive(false)}/>
			<div className={cl.modal}>
				<div className={cl.modalBody}>
					{children}
				</div>
				<div className={cl.modalFooter}>
					<Button type="default" onClick={() => setIsActive(false)}>Закрыть</Button>
				</div>
			</div>
		</div>
	);
};

export default Modal;
import cl from "styles/components/Indicator.module.scss";

const Indicator = ({type, title = ""}) => {
	const classes = [cl.indicator, cl[type]];
	
	return (
		<span className={classes.join(" ")} title={title}/>
	);
};

export default Indicator;
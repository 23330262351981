import cl from "styles/pages/[fieldId].module.scss";
import {Fragment, useContext, useEffect, useState} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchJournalSpreadDate } from "redux/slices/journalSlice";
import { AppContext } from "providers/AppContextProvider";

const Spread = () => {
	const navigate = useNavigate();
  const { setCrumbs } = useContext(AppContext);
	const dispatch = useDispatch();

	const { journalSpreadDate } = useSelector((state) => state.journal);

	// Данные с сервера
	const [data, setData] = useState([]);
	
	// Текущая дата
	const dateObj = new Date();
	const date = String(dateObj.getDate()).padStart(2, "0") + "." + String(dateObj.getMonth() + 1).padStart(2, "0") + "." + dateObj.getFullYear() + " " + String(dateObj.getHours()).padStart(2, "0") + ":" + String(dateObj.getMinutes()).padStart(2, "0");
	
	// Поле сортировки и направление
	const [sorted, setSorted] = useState("name");
	const [sortDir, setSortDir] = useState(true);
	
	// Классы таблицы
	const tableClasses = [cl.mainTable, "table", "table-responsive"];
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "Сверка складской базы и Агроплана", url: ""},
		]);
	}, []);
	
	// Скачивание отчета
	const print = () => {
		axios.post(process.env.REACT_APP_SERVER_ENTRYPOINT + "/journals/printSpread", {
			data: data,
			date: date
		}).then(response => {
			navigate("/FILES/" + response.data.filename);
      setTimeout(() => navigate("/journals/spread"), 1);
		});
	}
	
	// Функция сортировки растений
	const sortPlants = (name) => {
		setSortDir(!sortDir);
		
		const pl = Object.assign([], data);
		
		pl.sort((a, b) => {
			if(a[name] === b[name]) return 0;
			else if(!sortDir === true) return a[name] > b[name] ? 1 : -1;
			else return a[name] < b[name] ? 1 : -1;
		});
		
		setData(pl);
		setSorted(name);
	}

  useEffect(() => {
    dispatch(fetchJournalSpreadDate());
  }, []);

  useEffect(() => {
    if (journalSpreadDate.data.length > 0) {
		  setData(journalSpreadDate.data);
		}
  }, [journalSpreadDate.data]);
	
	return (
		<>
			<title>Сверка складской базы и Агроплана</title>
			
			<div className={cl.floatPanel + " " + cl.controlsPanel}>
				<span className="roundIcon material-icons" onClick={print} title="Скачать отчет">
					download
				</span>
				<span className="roundIcon material-icons" onClick={() => window.open("/faq/journalSpread", "_blank")} title="Инструкция">
					quiz
				</span>
			</div>
			
			<div className="stickyContainer">
				<table className={tableClasses.join(" ")} style={{marginTop: 0}}>
					<thead className="theadBordered thead-dark" style={{top: "0"}}>
						<tr>
							<th rowSpan="3">№</th>
							<th rowSpan="3" className={sorted === "name" ? "sort sorted" : "sort"}
								 onClick={() => sortPlants("name")}>
								<span>Название</span>
							</th>
							<th rowSpan="3">Артикул</th>
							<th rowSpan="3" className={sorted === "b_group" ? "sort sorted" : "sort"}
								 onClick={() => sortPlants("b_group")}>
								<span>Группа</span>
							</th>
							<th colSpan="3">Кол-во в Системе</th>
							<th colSpan="2">{date}</th>
						</tr>
						<tr>
							<th rowSpan="2">Всего</th>
							<th colSpan="2">В том числе:</th>
							<th rowSpan="2">Кол-во в Бизнес.ру</th>
							<th rowSpan="2">Расхождение</th>
						</tr>
						<tr>
							<th>Кол-во</th>
							<th>Адрес</th>
						</tr>
					</thead>
					<tbody>
						{data.map((dt, index) =>
							<Fragment key={index.toString()}>
								{dt.positions.length > 0 && dt.positions.map((pos, ind) =>
									<tr key={ind.toString()}>
										{ind === 0 &&
											<>
												<td rowSpan={dt.positions.length} className={cl.center + " " + cl.cifer}>
													{index + 1}
												</td>
												<td rowSpan={dt.positions.length} className={cl.name + " " + cl.router}
													 onClick={() => navigate("/history?name=" + dt.name)}>
													{dt.name}
												</td>
												<td rowSpan={dt.positions.length} className={cl.router}
													 onClick={() => navigate("/history?name=" + dt.name)}>
													{dt.article}
												</td>
												<td rowSpan={dt.positions.length}>{dt.b_group}</td>
												<td rowSpan={dt.positions.length} className={cl.right}>
													{new Intl.NumberFormat("ru-RU").format(dt.amount)}
												</td>
											</>
										}
										
										<td className={cl.right}>{new Intl.NumberFormat("ru-RU").format(pos.amount)}</td>
										<td>{pos.address}</td>
										
										{ind === 0 &&
											<>
												<td rowSpan={dt.positions.length} className={cl.right}>
													{typeof dt.remains === "undefined"
														? <span className="material-icons spinner">rotate_right</span>
														: new Intl.NumberFormat("ru-RU").format(dt.remains)
													}
												</td>
												<td rowSpan={dt.positions.length} className={cl.right}>
													{typeof dt.remains === "undefined"
														? <span className="material-icons spinner">rotate_right</span>
														: new Intl.NumberFormat("ru-RU").format(parseInt(dt.amount) - parseInt(dt.remains))
													}
												</td>
											</>
										}
									</tr>
								)}
							</Fragment>
						)}
					</tbody>
				</table>
			</div>
		</>
	);
};

// export async function getServerSideProps(){
// 	const apiToken = "Bearer ~ybqF7}S9G9a5@8}aIex";
	
// 	// Данные по всем полям
// 	const dataResponse = await axios.get("journals/spreadData", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const Data = dataResponse.data.result;
	
// 	// Сортировка числовых значений в обход строковых
// 	Data.map((dt, ind) => {
// 		Data[ind].positions = dt.positions.sort((a, b) => {
// 			if(a.address === b.address) return 0;
// 			else{
// 				const aa = a.address.split(".")[0];
// 				const bb = b.address.split(".")[0];
				
// 				if(aa === bb || isNaN(parseInt(aa)) || isNaN(parseInt(bb))) return 0;
// 				else return parseInt(aa) > parseInt(bb) ? 1 : -1;
// 			}
// 		});
// 	});
	
// 	return {props: {Data}}
// }

export default Spread;
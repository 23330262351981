import { useEffect, useState, Suspense, lazy, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "providers/AppContextProvider";
import Input from "components/input/InputComponent";
import Button from "components/ButtonComponent";
import SpinPlaceholderComponent from "components/preloader/SpinPlaceholderComponent";
import cl from "styles/pages/signup.module.scss";

const SetMapComponent = lazy(() => import("components/map/SetMap"));

const Add = () => {
	// Импорт карты
	// const NewMap = dynamic(
	// 	() => import("components/map/SetMap"),
	// 	{
	// 		loading: () => <Preloader isActive={true}/>,
	// 		ssr: false
	// 	}
	// );
	
	const router = useNavigate();
	
	// Состояние этапа с картой
	const [isMapVisible, setIsMapVisible] = useState(false);
	
	// Данные форм
	const [form, setForm] = useState({
		mapName: ""
	});

  const { alert, setCrumbs } = useContext(AppContext);

	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "Новая карта", url: ""},
		]);
	}, []);
	
	// Изменение данных форм
	const setValue = (name, value) => {
		const copy = Object.assign({}, form);
		copy[name] = value;
		setForm(copy);
	}
	
	// Переход на страницу с картами
	const goBack = () => {
		router.push("/");
	}
	
	// Переход далее
	const goNext = () => {
		// Валидация
		if(form.mapName.trim() === ""){
			alert("Введите название карты", "danger");
			return false;
		}
		
		// Показ карты
		setIsMapVisible(true);
	}
	
	// Переход далее по нажатию Enter
	const handleEnter = (e) => {
		if(e.key === "Enter") goNext();
	}
	
	return (
		<>
			<title>Добавить карту</title>
			
			{/* {children} */}
			
			<div className={cl.main + " container"} style={isMapVisible ? {display: "none"} : {}}>
				<form onSubmit={(e) => e.preventDefault()}>
					<Input type="text" label="Название карты" name="mapName" placeholder="введите название карты"
							 value={form.mapName} setValue={setValue} required={true}
							 onKeyDown={handleEnter}/>
					
					<div className="row">
						<div className="col-sm-6">
							<Button type="default" onClick={goBack}>Назад</Button>
						</div>
						<div className="col-sm-6">
							<Button type="accent" onClick={goNext}>Далее</Button>
						</div>
					</div>
				</form>
			</div>
			
			{isMapVisible && (
				<Suspense fallback={<SpinPlaceholderComponent isActive={true} />}>
					<SetMapComponent mapName={form.mapName}/>
				</Suspense>
			)}
		</>
	);
};

export default Add;
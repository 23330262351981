import L from "leaflet";
import {useEffect, useMemo, useRef, useState} from "react";
import {Marker, useMapEvents} from "react-leaflet";

const PolygonEditableMarker = ({position, changePosition, coordIndex, setIsDrawing}) => {
	// Инициализация маркера
	const markerIcon = L.icon({
		iconUrl: `${process.env.REACT_APP_SERVER_URL}/img/marker-field-icon.png`,
		iconSize: [10, 10],
		iconAnchor: [4, 4]
	});
	
	// Состояние перемещения маркера
	const [isDragging, setIsDragging] = useState(false);
	
	// Текущий маркер
	const markerRef = useRef(null);
	
	// Указание состояния отрисовки полигона
	useEffect(() => {
		setIsDrawing(isDragging);
	}, [isDragging]);
	
	// Перемещение вершины
	const eventHandlers = useMemo(() => ({
		click(){
			const removeDragging = () => {
				setIsDragging(false);
				document.removeEventListener("click", removeDragging);
			}
			
			setIsDragging(true);
			
			// Небольшой таймаут для подвязки ивента на документ, иначе перемещение сразу сбрасывается
			setTimeout(() => {
				document.addEventListener("click", removeDragging);
			}, 500);
		}
	}), []);
	
	// Подтягивание линий
	const map = useMapEvents({
		mousemove: (e) => {
			if(!isDragging) return;
			
			const c = map.mouseEventToLatLng(e.originalEvent);
			changePosition(coordIndex, [c.lat, c.lng]);
		}
	});
	
	return position === null ? null :
		<Marker ref={markerRef} eventHandlers={eventHandlers} position={position} icon={markerIcon}/>
};

export default PolygonEditableMarker;
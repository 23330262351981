import { configureStore } from "@reduxjs/toolkit";

import user from "redux/slices/userSlice";
import map from "redux/slices/mapSlice";
import field from "redux/slices/fieldSlice";
import journal from "redux/slices/journalSlice";
import inventory from "redux/slices/inventorySlice";
import business from "redux/slices/businessSlice";
import doc from "redux/slices/docSlice";
import history from "redux/slices/historySlice";

export const store = configureStore({
  reducer: {
    user,
    map,
    field,
    journal,
    inventory,
    business,
    doc,
    history,
  },
  devTools: process.env.NODE_ENV !== "production",
});
